import { MandatoryFields } from 'helpers/consultancyContract'
import { id } from 'helpers/ref'
import React from 'react'
import { t } from 'utils/i18n'

import Toast from 'ui/banners/toast'
import { ToastVariants } from 'ui/banners/toast.styled'
import { Button } from 'ui/buttons/button'
import { useQueryClient } from '@tanstack/react-query'
import { Opportunity } from 'lib/oleenApi'

export interface SendConsultancyContractToastProps {
  opportunity: Opportunity
  isValid: boolean
  missingFields: MandatoryFields
}

export const SendConsultancyContractToast: React.FC<SendConsultancyContractToastProps> = ({
  isValid,
  opportunity,
  missingFields,
}) => {
  const queryClient = useQueryClient()

  return isValid ? (
    <Toast
      text={
        <div>
          <span className="font-semibold truncate">{t('opportunity.consultancy_modal.toast_highlight')}</span>
          <div>{t('opportunity.consultancy_modal.toast')}</div>
        </div>
      }
      variant={ToastVariants.GRAY}
      closable
    />
  ) : (
    <Toast
      text={
        <div className="flex flex-col lg:flex-row flex-end gap-x-3 lg:justify-between">
          <div>
            <span className="font-semibold truncate">
              {t('opportunity.consultancy_modal.missing_data.validation_message')}
              <ul>
                {missingFields.map(field => (
                  <li key={id()}> - {t(`opportunity.consultancy_modal.missing_data.${field}`)}</li>
                ))}
              </ul>
            </span>
            <div>{t('opportunity.consultancy_modal.missing_data.action')}</div>
          </div>
          <div className="flex space-x-3 mt-3 lg:mt-0">
            <Button
              className="button--outline button--warning button--small"
              onClick={async () =>
                // TODO: centralize query key management to facilitate invalidation
                queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunity.id] })
              }
            >
              {t('opportunity.consultancy_modal.missing_data.refresh_button')}
            </Button>

            <Button
              className="button--solid button--warning button--small"
              onClick={() => window.open(t('url.zou_project', { projectId: opportunity.projectId }), '_blank')}
            >
              {t('opportunity.consultancy_modal.missing_data.modify_button')}
            </Button>
          </div>
        </div>
      }
      variant={ToastVariants.ORANGE}
    />
  )
}
