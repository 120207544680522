import { XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export type BadgeProps = PropsWithChildren<{
  className: string
  LeftIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  RightIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  isCloseable?: boolean
  onClick?: () => void
}>

export const Badge: React.FC<BadgeProps> = ({ className, children, LeftIcon, RightIcon, isCloseable, onClick }) => (
  <div className={classNames(className, 'badge')}>
    {LeftIcon && <LeftIcon />}
    {children}
    {RightIcon && <RightIcon />}
    {isCloseable && (
      <button onClick={onClick}>
        <XMarkIcon />
      </button>
    )}
  </div>
)
