import { CheckIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'

export type DropdownOptionTextProps = {
  className?: string
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  isChecked?: boolean
  label: string
  Label?: React.ReactNode
  onClick?: () => void
  value?: DropdownOptionValue
  isHidden?: boolean
}

export const DropdownOptionText: React.FC<PropsWithChildren<DropdownOptionTextProps>> = ({
  className,
  Icon,
  isChecked,
  label,
  Label,
  onClick,
  value,
  isHidden,
}) => {
  if (isHidden) return <></>

  return (
    <button
      value={value ?? undefined}
      className={classNames('dropdownItem dropdownItem--text', className)}
      onClick={onClick}
    >
      {Icon && <Icon />}
      <span>{Label || label}</span>
      {isChecked && <CheckIcon />}
    </button>
  )
}
