import { ArrowPathIcon, EnvelopeIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { ENDPOINTS } from 'helpers/endpoints'
import { formatISODate } from 'helpers/format'
import { toast } from 'helpers/toast'
import { useFetchApi } from 'hooks/api.hook'
import React, { useCallback, useEffect, useMemo } from 'react'
import { t } from 'utils/i18n'

import { Button, ButtonProps } from 'ui/buttons/button'
import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { CardSignatureRow } from 'ui/legacy/molecules/card/cardSignatureRow'
import { MANDATE_STATUS, Opportunity } from 'lib/oleenApi'
import { useQueryClient } from '@tanstack/react-query'
import { CLICKS } from 'helpers/tracking'

interface IMandateRowProps {
  openMandateModal: () => void
  opportunity: Opportunity
}

type Foo = {
  label: string
  buttonLabel?: string
  buttonProps: Pick<ButtonProps, 'className' | 'onClick' | 'tracking'>
  Icon?: typeof ArrowPathIcon
}

export const DocumentsCardSendMandateRow: React.FC<IMandateRowProps> = ({ openMandateModal, opportunity }) => {
  const { mandateId, mandateStatus, mandateStatusUpdatedAt, mandateSignatures } = opportunity
  const { fetchApi, loading: loadingCancelMandate, error } = useFetchApi<void, any>(ENDPOINTS.CANCEL_MANDATE)
  const queryClient = useQueryClient()

  const handleCancelMandate = useCallback(async () => {
    if (!mandateId) return toast.error(t('opportunity.cancel_mandate_error'), undefined, { autoClose: 2000 })
    const payload = {
      mandate_id: mandateId,
    }

    await fetchApi({ param: mandateId, payload })
    // TODO: centralize query key management to facilitate invalidation
    await queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunity.id] })
  }, [fetchApi, mandateId, opportunity.id, queryClient])

  const { label, Icon, buttonLabel, buttonProps }: Foo = useMemo(() => {
    const date: string = formatISODate(mandateStatusUpdatedAt)

    switch (mandateStatus) {
      case MANDATE_STATUS.SIGNED:
        return {
          label: t('opportunity.mandate_signed', { date }),
          buttonLabel: t('opportunity.resend_mandate'),
          buttonProps: {
            className: 'button--primary button--secondary button--small',
            onClick: openMandateModal,
            tracking: [CLICKS.opportunityMandateSend],
          },
          Icon: ArrowPathIcon,
        }

      case MANDATE_STATUS.CANCELED:
        return {
          label: t('opportunity.mandate_canceled', { date }),
          buttonLabel: t('opportunity.send'),
          buttonProps: {
            className: 'button--outline button--primary button--small',
            onClick: openMandateModal,
            tracking: [CLICKS.opportunityMandateSend],
          },
          Icon: XCircleIcon,
        }
      case MANDATE_STATUS.CREATED:
        return {
          label: t('opportunity.mandate_created', { date }),
          buttonLabel: t('opportunity.cancel_mandate'),
          buttonProps: {
            className: 'button--outline button--danger button--small',
            onClick: handleCancelMandate,
            disabled: loadingCancelMandate,
            tracking: [CLICKS.opportunityMandateCancel],
          },
        }
      default:
        return {
          label: t('opportunity.no_mandate', { date }),
          buttonLabel: t('opportunity.send'),
          buttonProps: {
            className: 'button--outline button--primary button--small',
            onClick: openMandateModal,
            tracking: [CLICKS.opportunityMandateSend],
          },
          Icon: EnvelopeIcon,
        }
    }
  }, [handleCancelMandate, loadingCancelMandate, mandateStatus, mandateStatusUpdatedAt, openMandateModal])

  const toastError = useEffect(() => {
    if (!error) return

    if (error?.response.data.type === 'Mortgage::MandateAlreadyProcessingError') {
      toast.error(t('opportunity.cancel_mandate_conflict'), undefined, { autoClose: 2000 })
    } else {
      toast.error(t('opportunity.cancel_mandate_error'), undefined, { autoClose: 2000 })
    }
  }, [error])

  return (
    <VerticalItem>
      <div className="flex justify-between items-center">
        <div className="flex flex-col items-start">
          <p className="text-sm font-bold antialiased leading-6 text-gray-800">{t('opportunity.mandate')}</p>
          <div className="flex gap-1 items-center text-xs text-gray-500 font-normal antialiased">
            <InformationCircleIcon className="w-5 h-5" />
            {label}
          </div>
        </div>
        <Button {...buttonProps} isLoading={loadingCancelMandate}>
          {buttonLabel}
          {Icon && <Icon />}
        </Button>
      </div>
      {mandateSignatures?.map((signature, index) => (
        <div key={`mandate-signature-${index}`}>
          {signature.representative && (
            <CardSignatureRow
              signerRole={t('opportunity.representative')}
              signedAt={signature.representative.signedAt}
              email={signature.representative.email}
            />
          )}
          {signature.mortgagor && (
            <CardSignatureRow
              signerRole={t('opportunity.mortgagor')}
              signedAt={signature.mortgagor.signedAt}
              email={signature.mortgagor.email}
            />
          )}
        </div>
      ))}
      {toastError}
    </VerticalItem>
  )
}
