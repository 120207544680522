import classNames from 'classnames'
import React, { forwardRef } from 'react'

import { FieldState } from 'ui/forms/form.hook'
import { TextInput } from 'ui/inputs/textInput'

export interface FormTextInputProps extends FieldState {
  textInputClassName?: string
  disabled?: boolean
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  id?: string
  placeholder?: string
  onFocus?: () => void
  autoFocus?: boolean
  enableClear?: boolean
}

export const FormTextInput = forwardRef<HTMLInputElement, FormTextInputProps>(
  ({ Icon, id, placeholder, ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        className={classNames(props.textInputClassName, {
          'textInput--error': !!props.error,
          'textInput--medium': !props.textInputClassName?.includes('textInput--small'),
        })}
        placeholder={placeholder}
        Icon={Icon}
        id={id}
        onChange={props.handleFieldChange}
        onBlur={() => props.handleFieldBlur(props.value as string)}
        onFocus={props.onFocus}
        value={props.value as string}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        enableClear={props.enableClear}
      />
    )
  }
)
FormTextInput.displayName = 'FormTextInput'
