import styled from 'styled-components'

export enum BannerVariants {
  GREEN,
  GRAY,
  ORANGE,
}

interface BannerProps {
  variant: BannerVariants
}

export const Banner = styled.div.attrs<BannerProps>(({ variant }) => {
  const baseStyles = 'flex gap-x-4 py-2 px-4 items-center rounded-lg text-sm font-medium'

  switch (variant) {
    case BannerVariants.GREEN:
      return {
        className: `${baseStyles} bg-green-600 text-green-900`,
        role: 'alert',
      }
    case BannerVariants.GRAY:
      return {
        className: `${baseStyles} bg-gray-100 text-gray-900`,
        role: 'alert',
      }
    case BannerVariants.ORANGE:
      return {
        className: `${baseStyles} bg-amber-100 text-amber-700`,
        role: 'alert',
      }
    default:
      return null
  }
})<BannerProps>``
