import styled from 'styled-components'

interface ILabelledCardLayoutProps {
  fullHeight?: boolean
  borderRadius?: string
}

export const CardContainer = styled.div.attrs(({ fullHeight, borderRadius }: ILabelledCardLayoutProps) => ({
  className: `${borderRadius || 'rounded-lg'} ${fullHeight ? 'h-full' : ''} border rounded-lg border-gray-300`,
}))<ILabelledCardLayoutProps>``

export const LabelledCardHead = styled.div.attrs({
  className: `bg-gray-100 rounded-lg border-gray-200 border-b flex justify-between p-3 font-bold text-sm h-fit text-gray-800`,
})`
  .card_title {
    margin-left: 10px;
  }
`
export const LabelledCardHorizontalBody = styled.div.attrs({
  className: 'flex p-4',
})``

export const LabelledCardVerticalBody = styled.div.attrs({
  className: 'flex flex-col text-xs p-4 space-y-1 font-normal leading-5',
})``

export const LabelledCardVerticalItem = styled.div.attrs({
  className: 'p-4 border-t text-xs items-center space-y-1',
})``

export const CardSignatureRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  line-height: 20px;
  margin-top: 8px;
`

export const CardSignatureRowLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  color: rgb(31 41 55);
`
