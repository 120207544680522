import { useSessionContext } from 'contexts/session'
import { apiRq } from 'helpers/api'
import { GetEndpointFunc } from 'helpers/endpoints'
import { Reducer, useCallback, useReducer } from 'react'

interface FetchApiState<T, E> {
  data: T | null
  loading: boolean
  error: E | null
}

type FetchApiHook = <T, E = void>(endpoint: GetEndpointFunc) => FetchApiState<T, E> & { fetchApi: FetchApiCallback<T> }
type FetchReducerAction<T, E> = { type: ACTIONS } & Partial<FetchApiState<T, E>>

type FetchApiCallbackArgs = { param?: string; payload?: unknown }
type FetchApiCallback<T> = (args?: FetchApiCallbackArgs) => Promise<T | void>

const INITIAL_STATE: FetchApiState<never, never> = {
  error: null,
  loading: false,
  data: null,
}

enum ACTIONS {
  FETCHED,
  FETCHING,
  ERROR,
}

const reducer = <T, E>(
  prevState: FetchApiState<T, E>,
  { type, ...nextState }: FetchReducerAction<T, E>
): FetchApiState<T, E> => {
  switch (type) {
    case ACTIONS.FETCHING:
      return { ...prevState, loading: true }
    case ACTIONS.FETCHED:
      return {
        ...prevState,
        ...nextState,
        loading: false,
      }
    case ACTIONS.ERROR:
      return {
        ...prevState,
        error: nextState.error || null,
        loading: false,
      }
    default:
      return prevState
  }
}

export const useFetchApi: FetchApiHook = <D, E = void>(endpoint: GetEndpointFunc) => {
  const { session } = useSessionContext()

  const [state, dispatch] = useReducer<Reducer<FetchApiState<D, E>, FetchReducerAction<D, E>>>(reducer, INITIAL_STATE)

  const fetchApi = useCallback<FetchApiCallback<D>>(
    ({ param, payload } = {}) => {
      const { path, verb, options } = endpoint(param)

      dispatch({ type: ACTIONS.FETCHING })

      return apiRq<D, E>(path, verb, session?.token || '', payload || {}, options)
        .then(res => {
          const { data } = res
          if (data.error) {
            dispatch({ type: ACTIONS.ERROR, error: data.error })
            return
          }

          dispatch({ type: ACTIONS.FETCHED, data })
          return data
        })
        .catch(error => dispatch({ type: ACTIONS.ERROR, error }))
    },
    [endpoint, session?.token]
  )

  return { ...state, fetchApi }
}
