import { EditTaskModal } from 'components/tasks/editTaskModal'
import React from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Task } from 'lib/oleenApi/services/tasksService'

import { FormProvider } from 'ui/forms/form'

export const EditTaskPage: React.FC = () => {
  const { taskId } = useParams()
  const navigate = useNavigate()
  const { task } = useOutletContext<{ task: Task }>()

  /* FIXME: We should implement a leaner way to retrieve the opportunities attribute */
  return (
    <FormProvider>
      {taskId && <EditTaskModal task={task} closeModal={() => navigate(`${ROUTES.BACK}/${ROUTES.BACK}`)} />}
    </FormProvider>
  )
}
