import { BellIcon } from '@heroicons/react/20/solid'
import { NotificationMenuList } from 'components/notifications/notificationMenuList'
import { NotificationMenuListBackdrop } from 'components/notifications/notificationMenuListBackdrop'
import { NotificationMenuListWrapper } from 'components/notifications/notificationMenuListWrapper'
import { NotificationsProvider, useNotificationsContext } from 'contexts/notifications'
import { ENDPOINTS, GetNotificationsResponse } from 'helpers/endpoints'
import { buildGetNotificationsQuery } from 'helpers/notification'
import { CLICKS, track } from 'helpers/tracking'
import { useFetchApi } from 'hooks/api.hook'
import { useInterval } from 'hooks/interval.hook'
import React, { useCallback, useEffect, useState } from 'react'

import { BADGED_BUTTON_CIRCLE_VARIANTS, BadgedButtonCircle } from 'ui/buttons/badgedButtonCircle'

export const NotificationMenuButtonAndList = () => {
  const { menuIsOpen, toggleMenu } = useNotificationsContext()
  const [badgeNumber, setBadgeNumber] = useState<number | undefined>(undefined)

  const { fetchApi: getNotifications, data: getNotificationsResponse } = useFetchApi<GetNotificationsResponse>(
    ENDPOINTS.GET_NOTIFICATIONS
  )

  const getUnseenNotifications = useCallback(
    () => getNotifications({ payload: buildGetNotificationsQuery({ unseen: true }) }),
    [getNotifications]
  )

  const { fetchApi: markNotificationsAsViewed } = useFetchApi<void>(ENDPOINTS.CREATE_NOTIFICATIONS_VIEWS)

  // Loads unseen notifications on component mount
  useEffect(() => {
    getUnseenNotifications()
  }, [getUnseenNotifications])

  // Updates the badge number whenever the api response changes
  useEffect(() => {
    if (getNotificationsResponse) {
      setBadgeNumber(getNotificationsResponse.total)
    }
  }, [getNotificationsResponse])

  // Query the api every minute
  const minute = 1000 * 60
  useInterval(getUnseenNotifications, minute)

  // Mark all as viewed when opening
  // Trigger api query when closing
  useEffect(() => {
    if (menuIsOpen) {
      track(CLICKS.notificationsMenu)
      markNotificationsAsViewed()
    } else {
      getUnseenNotifications()
    }
  }, [menuIsOpen, markNotificationsAsViewed, getUnseenNotifications])

  const onClick = () => {
    setBadgeNumber(undefined)
    toggleMenu()
  }

  return (
    <div className="relative">
      <div onClick={onClick}>
        <BadgedButtonCircle
          Icon={BellIcon}
          onClick={onClick}
          showBadge={!menuIsOpen && badgeNumber !== 0}
          badgeNumber={badgeNumber}
          variant={BADGED_BUTTON_CIRCLE_VARIANTS.GRAY}
        />
      </div>

      {menuIsOpen && <NotificationMenuListBackdrop onClick={onClick} />}
      <NotificationMenuListWrapper show={menuIsOpen}>
        <NotificationMenuList />
      </NotificationMenuListWrapper>
    </div>
  )
}

export const NotificationMenu = () => (
  <NotificationsProvider>
    <NotificationMenuButtonAndList />
  </NotificationsProvider>
)
