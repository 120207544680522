import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { CreateBusinessPartnerModal } from 'components/businessPartners/createBusinessPartnerModal'
import { EditBusinessPartnerModal } from 'components/businessPartners/editBusinessPartnerModal'
import { CreateLeadContributorModal } from 'components/leadContributors/createLeadContributorModal'
import { EditLeadContributorModal } from 'components/leadContributors/editLeadContributorModal'
import { useBusinessPartners } from 'contexts/businessPartners'
import { useLeadContributors } from 'contexts/leadContributors'
import { EMPTY_SIGN, formatFullName, formatText } from 'helpers/format'
import { BusinessPartner, LeadContributor } from 'lib/oleenApi'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { TextInput } from 'ui/inputs/textInput'
import { useModal } from 'ui/legacy/atoms/modals/modal.hook'
import { Table } from 'ui/tables/table'
import { useRepresentativeContext } from 'contexts/representative'
import { InvitationButton } from 'components/leadContributors/invitationButton'
import { CLICKS, track } from 'helpers/tracking'

type LeadContributorUpdateButtonProps = {
  leadContributor: LeadContributor
  edit: ({ leadContributor }: { leadContributor: LeadContributor }) => void
}

const LeadContributorEditButton: React.FC<LeadContributorUpdateButtonProps> = ({ leadContributor, edit }) => {
  return (
    <>
      <button
        onClick={() => {
          track(CLICKS.leadContributorEdit)
          edit({ leadContributor })
        }}
      >
        {formatFullName(leadContributor.firstName, leadContributor.lastName, EMPTY_SIGN)}
      </button>
      <p>{formatText(leadContributor.jobTitle)}</p>
    </>
  )
}

type BusinessPartnerUpdateButtonProps = {
  businessPartner: BusinessPartner
  edit: ({ businessPartner }: { businessPartner: BusinessPartner }) => void
}

const BusinessPartnerEditButton: React.FC<BusinessPartnerUpdateButtonProps> = ({ businessPartner, edit }) => {
  return (
    <button
      onClick={() => {
        track(CLICKS.businessPartnerEdit)
        edit({ businessPartner })
      }}
    >
      {businessPartner.name}
    </button>
  )
}

export const LeadContributorsPage: React.FC = () => {
  const { leadContributors } = useLeadContributors()
  const { getBusinessPartner } = useBusinessPartners()
  const [search, setSearch] = useState('')
  const createLeadContributorModalProps = useModal()
  const editLeadContributorModalProps = useModal<{ leadContributor: LeadContributor }>()
  const createBusinessPartnerModalProps = useModal()
  const editBusinessPartnerModalProps = useModal<{ businessPartner: BusinessPartner }>()

  const { representative } = useRepresentativeContext()

  /*
   * Adding `businessPartnerName` field to support sorting on businessPartner column
   */
  const tableData: (LeadContributor & { businessPartnerName: string | null })[] = useMemo(
    () =>
      leadContributors.map(lc => {
        const businessPartnerName = lc.businessPartnerId ? getBusinessPartner(lc.businessPartnerId)?.name ?? null : null
        return { ...lc, businessPartnerName }
      }),
    [leadContributors, getBusinessPartner]
  )

  const filteredData = useMemo(() => {
    if (!tableData) return null
    if (!search) return tableData

    return tableData.filter(
      item =>
        item.firstName?.toLowerCase().includes(search.toLowerCase()) ||
        item.lastName?.toLowerCase().includes(search.toLowerCase())
    )
  }, [tableData, search])

  return (
    <>
      <div className="flex flex-col items-start flex-1 self-stretch divide-y h-full">
        <header className="flex p-6 pb-4 flex-col items-start gap-4 self-stretch antialiased">
          <div className="flex justify-between items-center self-stretch">
            <div className="flex flex-1 items-center gap-2">
              <h1
                className="text-gray-900 text-3xl font-bold font-inter leading-9
"
              >
                {t('lead_contributors.page_title')}
              </h1>
              <p className="text-gray-500 text-3xl font-normal font-inter leading-9">
                {leadContributors && `(${leadContributors.length})`}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <Button
                tracking={[CLICKS.leadContributorAdd]}
                className="button--solid button--primary button--small"
                onClick={createLeadContributorModalProps.openModal}
              >
                {t('lead_contributors.new')}
              </Button>
              <Button
                tracking={[CLICKS.businessPartnerAdd]}
                className="button--solid button--primary button--small"
                onClick={createBusinessPartnerModalProps.openModal}
              >
                {t('business_partners.new')}
              </Button>
            </div>
          </div>
        </header>
        <section className="flex px-6 py-2 flex-col items-start gap-6 flex-1 grow self-stretch overflow-scroll">
          <div className="flex flex-col items-start self-stretch overflow-scroll">
            <div className="flex py-4 items-start gap-4 self-stretch sticky">
              <TextInput
                className="textInput--small w-[350px]"
                placeholder={t('common.search')}
                Icon={MagnifyingGlassIcon}
                value={search}
                onChange={setSearch}
              />
            </div>
            <div className="flex flex-col self-stretch overflow-scroll">
              {filteredData && (
                <Table
                  cols={[
                    {
                      name: t('lead_contributors.table.header.lead_contributor'),
                      className: 'tableCell--title max-w-[200px]',
                      render: leadContributor => (
                        <LeadContributorEditButton
                          leadContributor={leadContributor}
                          edit={editLeadContributorModalProps.openModal}
                        />
                      ),
                      sort: 'lastName',
                      sticky: true,
                    },
                    {
                      name: t('lead_contributors.table.header.business_partner_type'),
                      className: 'tableCell--text',
                      render: leadContributor =>
                        leadContributor.businessType
                          ? t(`business_partners.business_type.${leadContributor.businessType}`)
                          : EMPTY_SIGN,
                    },
                    {
                      name: t('lead_contributors.table.header.business_partner'),
                      className: 'tableCell--title',
                      render: leadContributor => {
                        const defaultName = formatFullName(
                          leadContributor.firstName,
                          leadContributor.lastName,
                          EMPTY_SIGN
                        )
                        if (!leadContributor.businessPartnerId) return defaultName
                        const businessPartner = getBusinessPartner(leadContributor.businessPartnerId)
                        if (!businessPartner) return EMPTY_SIGN
                        return (
                          <BusinessPartnerEditButton
                            businessPartner={businessPartner}
                            edit={editBusinessPartnerModalProps.openModal}
                          />
                        )
                      },

                      sort: 'businessPartnerName',
                    },
                    {
                      name: t('lead_contributors.table.header.email'),
                      className: 'tableCell--text',
                      render: leadContributor => leadContributor.email,
                    },
                    {
                      name: t('lead_contributors.table.header.phone'),
                      className: 'tableCell--text',
                      render: leadContributor => leadContributor.phone || EMPTY_SIGN,
                    },
                    {
                      name: t('lead_contributors.table.header.current_opportunities_count'),
                      className: 'tableCell--text',
                      render: leadContributor => {
                        const params = new URLSearchParams({ leadContributorId: leadContributor.id })
                        return (
                          <Link to={`${ROUTES.OPPORTUNITIES}?${params.toString()}`}>
                            {leadContributor.currentOpportunitiesCount}
                          </Link>
                        )
                      },
                      sort: 'currentOpportunitiesCount',
                    },
                    representative?.hasYuccanlead
                      ? {
                          name: t('lead_contributors.table.header.yuccanlead'),
                          className: 'tableCell--text',
                          render: leadContributor => <InvitationButton leadContributor={leadContributor} />,
                        }
                      : {
                          name: '',
                          className: 'hidden',
                          render: () => <></>,
                        }, // no choice with the current table component
                  ]}
                  items={filteredData}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <CreateLeadContributorModal {...createLeadContributorModalProps} />
      <CreateBusinessPartnerModal {...createBusinessPartnerModalProps} />
      {editLeadContributorModalProps.props?.leadContributor && (
        <EditLeadContributorModal {...editLeadContributorModalProps} {...editLeadContributorModalProps.props} />
      )}
      {editBusinessPartnerModalProps.props?.businessPartner && (
        <EditBusinessPartnerModal {...editBusinessPartnerModalProps} {...editBusinessPartnerModalProps.props} />
      )}
    </>
  )
}
