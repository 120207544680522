import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { track, Tracked } from 'helpers/tracking'
import React, { PropsWithChildren } from 'react'

type ButtonGroupListItemProps = {
  size?: 'small' | 'medium'
  position?: 'left' | 'center' | 'right'
  state?: 'default' | 'active'
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  isDropdown?: boolean
  className?: string
  onClick?: () => void
  disabled?: boolean
  title?: string
}

export const ButtonGroupItem: React.FC<PropsWithChildren<ButtonGroupListItemProps & Partial<Tracked>>> = ({
  size = 'small',
  state = 'default',
  position = 'center',
  Icon,
  isDropdown,
  children,
  className,
  onClick,
  disabled = false,
  title,
  tracking,
}) => {
  return (
    <button
      disabled={disabled}
      title={title}
      className={classNames(
        'flex justify-center items-center gap-2 shrink-0 border-x border-y border-gray-400 antialiased min-h-8 px-2 font-medium',
        {
          'hover:bg-gray-100': !disabled,
          'text-gray-400': disabled,
          'py-1': size === 'small',
          'py-2': size === 'medium',
          'h-8': size === 'small',
          'h-10': size === 'medium',
          'text-xs': size === 'small',
          'text-sm leading-6': size === 'medium',
          'text-royalBlue-600': state === 'active',
          'fill-royalBlue-600': state === 'active',
          'border-royalBlue-300': state === 'active',
          'bg-royalBlue-50': state === 'active',
          'rounded-l-lg': position === 'left',
          'border-l': position === 'left',
          'rounded-r-lg': position === 'right',
          'border-r': position === 'right',
        },
        className
      )}
      onClick={() => {
        if (tracking) track(...tracking)
        if (onClick) onClick()
      }}
    >
      {Icon && <Icon className="w-5 h-5" />}
      {children}
      {isDropdown && <ChevronDownIcon className="h-4 w-4" />}
    </button>
  )
}
