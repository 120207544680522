import { useEffect, useRef } from 'react'

export const useInterval = (callback: (...args: unknown[]) => void, delay?: number | null, ...args: unknown[]) => {
  const savedCallback = useRef<(...args: unknown[]) => void>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current(...args)
      }
    }
    if (delay !== null && delay !== undefined) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay, args])
}
