import { determineStackedBarItemPercentageShare } from 'helpers/opportunity'
import { id } from 'helpers/ref'
import React from 'react'

import { StackedBarItem, StackedBarWrapper } from '../tooltipSumCategory.styled'
import { FeesColors, TooltipStackedBarProps } from '../tooltipSumCategory.types'

export const TooltipSumCategoryStackedBar = ({ items }: TooltipStackedBarProps) => {
  const stackedBar = items.filter(item => item.amount !== 0)

  if (stackedBar.length) {
    return (
      <StackedBarWrapper>
        {stackedBar.map(item => (
          <StackedBarItem
            key={id()}
            isFirst={item === items[0]}
            isLast={item === items[items.length - 1]}
            color={FeesColors[item.label]}
            share={determineStackedBarItemPercentageShare(stackedBar, item.amount)}
          />
        ))}
      </StackedBarWrapper>
    )
  }

  return <StackedBarItem color={FeesColors.default} share={100} isFirst isLast />
}
