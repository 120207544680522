import {
  FeasabilityCertificateForm,
  getModalFinancingFields,
  getProjectRows,
  parseFeasabilityCertificatePayload,
} from 'helpers/documents'
import { id } from 'helpers/ref'
import React, { useCallback } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { useFormContext } from 'ui/forms/form'
import { Checkbox } from 'ui/legacy/atoms/checkbox/checkbox.component'
import { ICheckbox } from 'ui/legacy/atoms/checkbox/checkbox.types'
import { Modal } from 'ui/modals/modal'
import { LabelledCardVerticalBody as VerticalBody } from 'ui/legacy/molecules/card/card.styled'
import { CardDataRow } from 'ui/legacy/molecules/card/cardDataRow'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { useFeasabilityCertificate } from 'hooks/documents.hook'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'

export interface GenerateFeasabilityCertificateModalProps {
  opportunity: Opportunity
  closeModal: () => void
}

export const GenerateFeasabilityCertificateModal: React.FC<GenerateFeasabilityCertificateModalProps> = ({
  opportunity,
  closeModal,
}) => {
  const modalFinancingFields = getModalFinancingFields(opportunity)
  const { getFormData } = useFormContext()

  const { fetchFeasabilityCertificate, isFeasabilityLoading } = useFeasabilityCertificate()

  const onGenerateFeasabilityPDF = useCallback(async () => {
    const payload = getFormData()
    const parsedPayload = parseFeasabilityCertificatePayload(payload as FeasabilityCertificateForm)
    await fetchFeasabilityCertificate({ param: opportunity.id, payload: parsedPayload })
    closeModal()
  }, [closeModal, fetchFeasabilityCertificate, getFormData, opportunity.id])

  const renderProjectRows = () => {
    const projectRows = getProjectRows(opportunity)
    return projectRows.map(row => <CardDataRow key={id()} label={row.label} data={row.data} />)
  }

  const renderFinancingFields = useCallback(
    (options: Array<{ selectOption: ICheckbox; data: string }>) =>
      options.map(option => {
        const { selectOption, data } = option
        return (
          <div key={id()} className="flex flex-row grow justify-between leading-5">
            <Checkbox {...selectOption} />
            <span className="font-semibold truncate">{data}</span>
          </div>
        )
      }),
    []
  )

  return (
    <Modal
      className="modal--form md:!w-1/3"
      isOpen={true}
      onClose={closeModal}
      title={t('opportunity.feasability_modal.title')}
    >
      <div className="flex flex-col w-full gap-6">
        <LabelledCard icon={CARD_ICON.HomeIcon} title={t('opportunity.project_title')}>
          <VerticalBody>{renderProjectRows()}</VerticalBody>
        </LabelledCard>

        <LabelledCard icon={CARD_ICON.BankNotesIcon} title={t('opportunity.financing_title')}>
          <VerticalBody>{renderFinancingFields(modalFinancingFields)}</VerticalBody>
        </LabelledCard>
      </div>
      <footer className="flex justify-between px-8 py-4">
        <Button
          className="button--outline button--secondary button--small"
          onClick={closeModal}
          disabled={isFeasabilityLoading}
        >
          {t('modal_cancel')}
        </Button>
        <Button
          className="button--solid button--primary button--small"
          tracking={[CLICKS.opportunityFeasabilityCertificateGenerate]}
          onClick={onGenerateFeasabilityPDF}
          isLoading={isFeasabilityLoading}
        >
          <ArrowDownTrayIcon /> {t('opportunity.feasability_modal.generate')}
        </Button>
      </footer>
    </Modal>
  )
}
