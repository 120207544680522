import { ReactNode } from 'react'
import styled from 'styled-components'

import { CardContainer } from 'ui/legacy/molecules/card/card.styled'

export const Container = styled(CardContainer).attrs<{ children: ReactNode | ReactNode[] }>({
  className: 'absolute r-20 mt-2 p-4 min-w-[296px] w-[27px] bg-white border border-grey-200 rounded-lg z-50 shadow-sm',
})``

export const StyledInput = styled.input.attrs({
  className: 'flex h-full w-full outline-none bg-transparent',
})``
