import styled from 'styled-components'

import { CheckboxInputProps } from 'ui/legacy/atoms/checkbox/checkbox.types'

export const CheckboxInput = styled.input.attrs((p: CheckboxInputProps) => ({
  className: `rounded-lg cursor-pointer`,
  type: 'checkbox',
  name: `${p.name}`,
  value: `${p.value}`,
}))``

export const CheckboxLabel = styled.label.attrs(() => ({
  className: 'ml-2',
}))``

export const CheckboxRow = styled.div.attrs({ className: 'flex items-center' })``
