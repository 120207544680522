import {
  CreateBusinessPartnerForm,
  CreateBusinessPartnerFormData,
  CreateBusinessPartnerFormError,
} from 'components/businessPartners/createBusinessPartnerForm'
import { useBusinessPartners } from 'contexts/businessPartners'
import { useOleenApi } from 'contexts/oleenApi'
import { toast } from 'helpers/toast'
import { useAsync } from 'hooks/async.hook'
import { BusinessPartnerApiError } from 'lib/oleenApi'
import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { FormProvider, useFormContext } from 'ui/forms/form'
import { Modal } from 'ui/legacy/atoms/modals/modal'
import { MODAL_SIZES } from 'ui/legacy/atoms/modals/modal.styled'
import { CLICKS } from 'helpers/tracking'

type CreateBusinessPartnerModalContentProps = {
  closeModal: () => void
}

const CreateBusinessPartnerModalContent: React.FC<CreateBusinessPartnerModalContentProps> = ({ closeModal }) => {
  const { getFormData, isFormValid } = useFormContext()
  const { oleenApi } = useOleenApi()
  const [formError, setFormError] = useState<CreateBusinessPartnerFormError>({})
  const { upsertBusinessPartner } = useBusinessPartners()

  const createBusinessPartner = useCallback(() => {
    const businessPartner = getFormData() as CreateBusinessPartnerFormData
    return oleenApi.businessPartners().create(businessPartner)
  }, [oleenApi, getFormData])

  const { data, error, run } = useAsync(createBusinessPartner)

  useEffect(() => {
    if (!data) return

    toast.success(t('business_partners.toasts.creation.success'))
    upsertBusinessPartner(data)
    closeModal()
  }, [data, closeModal, upsertBusinessPartner])

  useEffect(() => {
    if (!error) return

    toast.error(t('business_partners.toasts.creation.error'))
    if (error instanceof BusinessPartnerApiError) {
      setFormError({})
      error.details.forEach(detail => {
        setFormError(prev => ({ ...prev, [detail.attribute]: detail.type }))
      })
    }
  }, [error])

  return (
    <CreateBusinessPartnerForm error={formError}>
      <Button
        className="button--solid button--primary button--medium"
        disabled={!isFormValid}
        onClick={run}
        tracking={[CLICKS.businessPartnerSave]}
      >
        <span>{t('business_partners.modal.create.submit')}</span>
      </Button>
      <Button className="button--solid button--secondary button--medium" onClick={closeModal}>
        <span>{t('common.cancel')}</span>
      </Button>
    </CreateBusinessPartnerForm>
  )
}

type CreateBusinessPartnerModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const CreateBusinessPartnerModal: React.FC<CreateBusinessPartnerModalProps> = ({ isOpen, closeModal }) => {
  if (!isOpen) return <></>

  return (
    <FormProvider>
      <Modal
        className="overflow-y-visible"
        isOpen={isOpen}
        closeModal={closeModal}
        size={MODAL_SIZES.MD}
        header={
          <div tabIndex={-1}>
            <p className="text-gray-900 text-base font-bold font-inter leading-6">
              {t('business_partners.modal.create.title')}
            </p>
          </div>
        }
      >
        <CreateBusinessPartnerModalContent closeModal={closeModal} />
      </Modal>
    </FormProvider>
  )
}
