import React, { ReactNode } from 'react'

import { KanbanColumnBody, KanbanColumnHeader, KanbanDisplayArea, KanbanWrapper } from './kanban.styled'
import { BOS_STATUSES_START_INDEX } from 'helpers/opportunity'
import { KanbanColumn } from 'components/kanban/kanbanColumn'
import { KanbanSeparator } from 'components/kanban/kanbanSeparator'
import { t } from 'utils/i18n'

export interface IKanbanProps<T> {
  dataMap: Record<string, T[]>
  renderDataItems: (item: T) => ReactNode
  renderHeader: (header: string) => ReactNode
}

export const Kanban: <T>(props: IKanbanProps<T> & { children?: ReactNode }) => JSX.Element = ({
  dataMap,
  renderDataItems,
  renderHeader,
}) => {
  return (
    <KanbanDisplayArea>
      <KanbanWrapper>
        {Object.entries(dataMap).map(([columnTitle, data], index) => (
          <>
            {index === BOS_STATUSES_START_INDEX && <KanbanSeparator label={t('opportunity.statuses.bos_separator')} />}
            <KanbanColumn
              key={`kanbanColumn-${columnTitle}`}
              isStriped={index >= BOS_STATUSES_START_INDEX && index < Object.entries(dataMap).length - 1}
            >
              <KanbanColumnHeader>{renderHeader(columnTitle)}</KanbanColumnHeader>
              <KanbanColumnBody>{data.map(renderDataItems)}</KanbanColumnBody>
            </KanbanColumn>
          </>
        ))}
      </KanbanWrapper>
    </KanbanDisplayArea>
  )
}
