import { ENDPOINTS } from 'helpers/endpoints'
import { useFetchApi } from 'hooks/api.hook'
import React, { createContext, useCallback, useContext, useState } from 'react'

export interface INotificationsContext {
  menuIsOpen: boolean
  openMenu: () => void
  closeMenu: () => void
  toggleMenu: () => void
  markAsInteracted: (id: string) => void
}

export const NotificationsContext = createContext<INotificationsContext>({
  menuIsOpen: false,
  openMenu: () => null,
  closeMenu: () => null,
  toggleMenu: () => null,
  markAsInteracted: () => null,
})

export const NotificationsProvider: React.FC = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const openMenu = useCallback(() => setMenuIsOpen(true), [setMenuIsOpen])
  const closeMenu = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen])
  const toggleMenu = useCallback(() => (menuIsOpen ? closeMenu() : openMenu()), [menuIsOpen, closeMenu, openMenu])

  const { fetchApi } = useFetchApi<void>(ENDPOINTS.CREATE_NOTIFICATION_INTERACTION)

  const markAsInteracted = useCallback((id: string) => fetchApi({ param: id }), [fetchApi])

  return (
    <NotificationsContext.Provider
      value={{
        menuIsOpen,
        openMenu,
        closeMenu,
        toggleMenu,
        markAsInteracted,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotificationsContext: () => INotificationsContext = () => useContext(NotificationsContext)
