import { SendConsultancyContractModal } from 'components/opportunity/molecules/sendConsultancyContractModal'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useConsultancyContract } from 'hooks/documents.hook'
import { FormProvider } from 'ui/forms/form'
import { ROUTES } from 'router/routes'
import { useQueryClient } from '@tanstack/react-query'
import { Opportunity } from 'lib/oleenApi'

export const NewConsultancyContractPage: React.FC = () => {
  const { opportunityId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // TODO: centralize query key management to facilitate invalidation
  const opportunity = queryClient.getQueryData(['mortgageOpportunity', opportunityId]) as Opportunity

  const { fetchConsultancyContract, isConsultancyContractLoading } = useConsultancyContract()

  return (
    <>
      {opportunity && (
        <FormProvider>
          <SendConsultancyContractModal
            opportunity={opportunity}
            closeModal={() => navigate(ROUTES.BACK)}
            fetchApi={fetchConsultancyContract}
            isLoading={isConsultancyContractLoading}
          />
        </FormProvider>
      )}
    </>
  )
}
