import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { formatFullName, formatText } from 'helpers/format'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Interrogation, Opportunity, OPPORTUNITY_STATUS } from 'lib/oleenApi'
import { t } from 'utils/i18n'

import { CardContainer } from 'ui/legacy/molecules/card/card.styled'
import { MaturityBadge } from 'components/opportunity/maturityBadge'
import _ from 'lodash'
import { MandateInfo } from './cardSummary/mandateInfo'
import { ConditionsPrecedentLiftDateInfo } from './cardSummary/conditionsPrecedentLiftDateInfo'
import { CompletionInfo } from './cardSummary/completionInfo'
import { BankAskedInfo } from './cardSummary/bankAskedInfo'
import { BankAppointmentDateInfo } from './cardSummary/bankAppointmentDateInfo'
import { CreationInfo } from './cardSummary/creationInfo'
import { LostReasonInfo } from './cardSummary/lostReasonInfo'
import { TasksBadge } from 'components/opportunity/tasksBadge'
import { isBosAlertStatus } from 'helpers/opportunity'

interface ICardSummary {
  opportunity: Opportunity
  isMobile?: boolean
}

const config = {
  maturity: [OPPORTUNITY_STATUS.A_JOINDRE, OPPORTUNITY_STATUS.RELANCE_DOC],
  conditionsPrecedentLiftDate: [
    OPPORTUNITY_STATUS.EN_MONTAGE,
    OPPORTUNITY_STATUS.ENVOYE_BANQUE,
    OPPORTUNITY_STATUS.ACCORD_BANQUE,
    OPPORTUNITY_STATUS.ACCORD_CLIENT,
    OPPORTUNITY_STATUS.PLANNED_BANK_MEETING,
    OPPORTUNITY_STATUS.EDITED_OFFER,
    OPPORTUNITY_STATUS.SIGNED_OFFER,
  ],
  completion: [OPPORTUNITY_STATUS.RELANCE_DOC, OPPORTUNITY_STATUS.EN_MONTAGE],
  mandate: [OPPORTUNITY_STATUS.EN_MONTAGE],
  bankAsked: [OPPORTUNITY_STATUS.ENVOYE_BANQUE],
  bankOk: [OPPORTUNITY_STATUS.ACCORD_BANQUE, OPPORTUNITY_STATUS.ACCORD_CLIENT],
  bankAppointmentDate: [OPPORTUNITY_STATUS.PLANNED_BANK_MEETING],
  lostReason: [OPPORTUNITY_STATUS.PERDU],
  creation: [OPPORTUNITY_STATUS.A_JOINDRE, OPPORTUNITY_STATUS.RELANCE_DOC],
}

const interrogationFilter = {
  bankAsked: (interrogation: Interrogation) =>
    interrogation.sentAt !== null && !['dropped', 'denied'].includes(interrogation.bankResponseStatus ?? ''),
  bankOk: (interrogation: Interrogation) =>
    interrogation.sentAt !== null &&
    ['sales_agreemnt', 'risk_agreement'].includes(interrogation.bankResponseStatus ?? ''),
}

export const CardSummary: React.FC<ICardSummary> = ({ opportunity }) => {
  const { mortgagorFirstName, mortgagorLastName, id: opportunityId } = opportunity

  return (
    <Link to={generatePath(ROUTES.OPPORTUNITY, { opportunityId })}>
      <CardContainer borderRadius="bg-white antialiased cursor-pointer">
        <div className="flex-col p-3 text-gray-900 space-y-3">
          <div className="grow truncate">
            <span className="text-sm font-semibold leading-6">
              {formatFullName(mortgagorFirstName, mortgagorLastName)}
            </span>
            {opportunity.subtitle && (
              <p className="text-xs font-normal antialiased leading-4 text-gray-500 overflow-hidden text-ellipsis">
                {opportunity.subtitle}
              </p>
            )}
          </div>

          <div className="flex-col space-y-1">
            {config.maturity.includes(opportunity.status) && <MaturityBadge maturity={opportunity.maturity} />}
            {config.conditionsPrecedentLiftDate.includes(opportunity.status) && (
              <ConditionsPrecedentLiftDateInfo opportunity={opportunity} />
            )}
            {config.completion.includes(opportunity.status) && <CompletionInfo opportunity={opportunity} />}
            {config.mandate.includes(opportunity.status) && <MandateInfo opportunity={opportunity} />}
            {(['bankAsked', 'bankOk'] as const)
              .filter(status => config[status].includes(opportunity.status))
              .map(status => {
                return opportunity.interrogations
                  ?.filter(interrogationFilter[status])
                  ?.map((interrogation, i) => <BankAskedInfo key={i} interrogation={interrogation} />)
              })}
            {config.bankAppointmentDate.includes(opportunity.status) && (
              <BankAppointmentDateInfo opportunity={opportunity} />
            )}
            {config.lostReason.includes(opportunity.status) && opportunity.last_mortgage_deal_loss && (
              <LostReasonInfo opportunity={opportunity} />
            )}
            {config.creation.includes(opportunity.status) && <CreationInfo opportunity={opportunity} />}
            {opportunity.areBankResponsesExpected && isBosAlertStatus(opportunity.status) && (
              <div className="text-xs text-orange-500 flex items-center gap-x-2">
                <p className="flex-none"> {t('opportunity_kanban.submit_bank_response_warning')} </p>
                <ExclamationCircleIcon className="w-5 h-5" />
              </div>
            )}
          </div>
          {opportunity.remainingTasksCount > 0 && <TasksBadge tasksCount={opportunity.remainingTasksCount} />}
        </div>
      </CardContainer>
    </Link>
  )
}
