import { DocumentDuplicateIcon } from '@heroicons/react/20/solid'
import { AnnotationCard } from 'components/opportunity/molecules/annotationCard'
import { BackofficeCard } from 'components/opportunity/molecules/backofficeCard'
import { ContactCard } from 'components/opportunity/molecules/contactCard'
import { DocumentsCard } from 'components/opportunity/molecules/documentsCard/documentsCard'
import { FinancingCard } from 'components/opportunity/molecules/financingCard'
import { InterrogationsCard } from 'components/opportunity/molecules/interrogationsCard'
import { LostReasonCard } from 'components/opportunity/molecules/lostReasonCard'
import { ProjectCard } from 'components/opportunity/molecules/projectCard'
import { SourceCard } from 'components/opportunity/molecules/sourceCard'
import { TasksCard } from 'components/opportunity/molecules/tasksCard/tasksCard'
import React, { Ref } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { Grid, GridItem } from 'ui/legacy/molecules/grid/grid.styled'
import { Opportunity, OPPORTUNITY_STATUS } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'
import OpportunityAlerts from 'components/opportunity/opportunityAlerts'
import OpportunityInfos from 'components/opportunity/opportunityInfos'

interface OpportunityBodyProps {
  opportunity: Opportunity
  openMandateModal: () => void
  openDuplicateModal: () => void
  bankAppointmentOnRef: Ref<HTMLInputElement>
}

const OpportunityDetails: React.FC<OpportunityBodyProps> = ({
  opportunity,
  openMandateModal,
  openDuplicateModal,
  bankAppointmentOnRef,
}) => {
  const navigate = useNavigate()

  return (
    <div className="overflow-y-scroll px-6 scroll-smooth">
      <div className="py-6 mb-16 flex flex-col gap-4">
        <OpportunityAlerts opportunity={opportunity} />
        <OpportunityInfos opportunity={opportunity} />
        <Grid templateColumns="grid-cols-6" gap="gap-x-4 gap-y-6">
          <GridItem colSpan="col-span-6 md:col-span-4">
            <ContactCard opportunity={opportunity} />
          </GridItem>
          <GridItem colSpan="col-span-6 md:col-span-2">
            <SourceCard opportunity={opportunity} />
          </GridItem>
          <GridItem colSpan="col-span-6">
            <TasksCard opportunity={opportunity} />
          </GridItem>
          {opportunity.projectId && (
            <>
              <GridItem colSpan="col-span-6">
                <DocumentsCard opportunity={opportunity} openMandateModal={openMandateModal} />
              </GridItem>
              <GridItem colSpan="col-span-6">
                <AnnotationCard opportunity={opportunity} />
              </GridItem>
              {opportunity.interrogations && opportunity.interrogations.length > 0 && (
                <GridItem colSpan="col-span-6" id="interrogations_card">
                  <InterrogationsCard opportunity={opportunity} />
                </GridItem>
              )}
              <GridItem colSpan="grid col-span-6 md:col-span-3 gap-6">
                <ProjectCard opportunity={opportunity} />
                <BackofficeCard opportunity={opportunity} bankAppointmentOnRef={bankAppointmentOnRef} />
              </GridItem>
              <GridItem colSpan="grid col-span-6 md:col-span-3 gap-6 h-fit">
                <FinancingCard opportunity={opportunity} />
                {opportunity.status === OPPORTUNITY_STATUS.PERDU && <LostReasonCard opportunity={opportunity} />}
              </GridItem>
            </>
          )}
          <GridItem colSpan="col-span-6 w-fit ml-auto flex space-x-4">
            <Button
              className="button--outline button--secondary button--small"
              tracking={[CLICKS.opportunityDuplicate]}
              onClick={openDuplicateModal}
              disabled={!opportunity.projectId}
            >
              <DocumentDuplicateIcon />
              {t('opportunity.duplicate')}
            </Button>
            {opportunity.status !== OPPORTUNITY_STATUS.PERDU && (
              <Button
                className="button--solid button--danger button--small"
                tracking={[CLICKS.opportunityDrop]}
                onClick={() => navigate('drop')}
              >
                {t('opportunity.drop')}
              </Button>
            )}
          </GridItem>
        </Grid>
      </div>
    </div>
  )
}

export default OpportunityDetails
