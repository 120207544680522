import React from 'react'

export type CertificateItem = {
  label: string
  certificateType: CertificateType
  hasModal?: boolean
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
}

export enum CertificateType {
  Feasability = 'Feasability',
  Deposit = 'Deposit',
  Simulation = 'Simulation',
}
