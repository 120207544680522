import React from 'react'

import _ from 'lodash'
import { ProgressBarFill } from './progressBarFill'
import classNames from 'classnames'

type IProgressBar = {
  className?: string
  value: number
  isValueVisible?: boolean
}

const ProgressBar: React.FC<IProgressBar> = ({ className, value }) => (
  <div
    className={classNames(
      'progressBar',
      {
        'progressBar-right': !className?.includes('progressBar-'),
      },
      className
    )}
  >
    <span className="text-xs text-gray-500 leading-5 mb-0.5">{value || 0}%</span>
    <div className="w-full rounded-sm h-1.5 bg-gray-200">
      <ProgressBarFill className="bg-blue-600 h-1.5 rounded-sm" width={value} />
    </div>
  </div>
)

export default ProgressBar
