import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { NavBar } from 'components/navBar/navBar'
import { Notification } from 'components/notifications/notification'
import { useNotificationsContext } from 'contexts/notifications'
import { ENDPOINTS, GetNotificationsResponse } from 'helpers/endpoints'
import { buildGetNotificationsQuery, parseNotifications } from 'helpers/notification'
import { useFetchApi } from 'hooks/api.hook'
import { useElementOnScreen } from 'hooks/elementOnScreen.hook'
import React, { useEffect, useState } from 'react'
import { Notification as INotification } from 'types/notification'
import { t } from 'utils/i18n'

import { NotificationTray, NotificationTraySection } from 'ui/notifications/notificationTray'
import { Spinner } from 'ui/spinner/spinner'

export const NotificationMenuList: React.FC = () => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [nextPage, setNextPage] = useState<number | null>(1)
  const [logoref, isVisible] = useElementOnScreen()

  const { fetchApi: getNotifications, data: getNotificationsResponse } = useFetchApi<GetNotificationsResponse>(
    ENDPOINTS.GET_NOTIFICATIONS
  )

  useEffect(() => {
    if (isVisible && nextPage) {
      getNotifications({ payload: buildGetNotificationsQuery({ page: nextPage }) })
    }
  }, [isVisible, nextPage, getNotifications])

  // When the api query is done, add notifications to the list and update next page
  useEffect(() => {
    if (!getNotificationsResponse) return

    const new_notifications = parseNotifications(getNotificationsResponse)
    setNotifications(notifications => notifications.concat(new_notifications.notifications))
    setNextPage(new_notifications.nextPage)
  }, [getNotificationsResponse])

  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)

  const justNowNotifications = notifications.filter(notification => notification.createdAt >= yesterday)

  const earlierNotifications = notifications.filter(notification => notification.createdAt < yesterday)

  return (
    <>
      <MenuListNavBar />
      <NotificationTray title={t('notification.title')}>
        {justNowNotifications && (
          <NotificationTraySection title={t('notification.new')}>
            {justNowNotifications.map(n => (
              <Notification key={n.id} notification={n} />
            ))}
          </NotificationTraySection>
        )}
        {earlierNotifications && (
          <NotificationTraySection title={t('notification.earlier')}>
            {earlierNotifications.map(n => (
              <Notification key={n.id} notification={n} />
            ))}
          </NotificationTraySection>
        )}

        <div className={`self-center ${nextPage ? 'block' : 'hidden'}`} ref={logoref}>
          <Spinner />
        </div>
      </NotificationTray>
    </>
  )
}

// top bar for mobile
const MenuListNavBar = () => {
  const { closeMenu } = useNotificationsContext()

  return (
    <div className="lg:hidden text-white">
      <NavBar>
        <ArrowLeftIcon onClick={closeMenu} className="h-6 w-6 cursor-pointer" />
        <p>Notifications</p>
        <ArrowLeftIcon className="h-6 w-6 opacity-0" /> {/* cheating */}
      </NavBar>
    </div>
  )
}
