import React, { ReactNode } from 'react'

export type NotificationItemProps = {
  Icon: ReactNode
  title?: ReactNode
  subtitle: ReactNode
  timing: ReactNode
  interacted?: boolean
  onClick?: () => void
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  Icon,
  title,
  subtitle,
  timing,
  interacted,
  onClick,
}) => {
  const i = (interactedStyle: string, notInteractedStyle: string) => (interacted ? interactedStyle : notInteractedStyle)
  return (
    <div
      onClick={onClick}
      className={`flex flex-row gap-3 p-3 rounded-xl border border-grey-200 ${i(
        'text-gray-400',
        'bg-blue-50'
      )} cursor-pointer`}
    >
      <div className="w-8 h-8 justify-center items-center flex flex-none">{Icon}</div>
      <div className="shrink overflow-hidden">
        <p className="antialiased text-xs font-semibold">{title}</p>
        <p className="antialiased text-xs font-normal leading-4.5">{subtitle}</p>
        <p className={`antialiased text-xs font-normal  ${i('text-gray-400', 'text-gray-500')}`}>{timing}</p>
      </div>
    </div>
  )
}

// Only used in the notification center.
export const NewIcon: React.FC = () => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" rx="8" fill="#1F2937" />
      <path d="M10.1392 13H11.6159V19H10.2825L7.48108 15.1494V19H6V13H7.48108L10.1392 16.6598V13Z" fill="white" />
      <path
        d="M13.4264 19V13H17.9044L17.8957 14.1784H14.9162V15.4025H17.457V16.556H14.9075V17.8091L17.9304 17.8133V19H13.4264Z"
        fill="white"
      />
      <path
        d="M27 13L25.1324 19H23.9944L22.9086 15.4066L21.8141 19H20.6935L18.8215 13H20.2591L21.332 16.5892L22.383 13H23.4341L24.5113 16.5892L25.5624 13H27Z"
        fill="white"
      />
    </svg>
  </div>
)
