import React from 'react'
import { FlagImage } from 'react-international-phone'
import { ParsedCountry } from 'react-international-phone/build/types'

export const CountryOption: React.FC<{ country: ParsedCountry }> = ({ country }) => {
  return (
    <p className="flex gap-3">
      <FlagImage iso2={country.iso2} /> {country.name} <span className="text-gray-400">+{country.dialCode}</span>
    </p>
  )
}
