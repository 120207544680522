import { CreateTaskModal } from 'components/tasks/createTaskModal'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'

import { FormProvider } from 'ui/forms/form'

export const CreateTaskPage: React.FC = () => {
  const navigate = useNavigate()

  return <FormProvider>{<CreateTaskModal closeModal={() => navigate(ROUTES.TASKS)} />}</FormProvider>
}
