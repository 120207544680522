import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { FolderIcon } from '@heroicons/react/24/solid'
import { formatFullName } from 'helpers/format'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { TextInput } from 'ui/inputs/textInput'
import { HighlightedIcon } from 'ui/legacy/atoms/highlightedIcon/highlightedIcon'
import { HIGHLIGHTED_ICON_SIZE, HIGHLIGHTED_ICON_VARIANTS } from 'ui/legacy/atoms/highlightedIcon/highlightedIcon.types'

import { Container } from './searchBar.styles'
import SearchBarOverlayItem from './searchBarOverlayItem'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS, track } from 'helpers/tracking'

export type SearchBarProps = {
  opportunities: Opportunity[]
}

export const SearchBar = ({ opportunities }: SearchBarProps) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [open, setOpen] = useState(false)
  const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity | null>(null)
  const navigate = useNavigate()

  const compareStrToInputValue = (str?: string | null) => str?.toLowerCase().includes(inputValue.toLowerCase())

  const filteredOpportunities = opportunities.filter(
    opportunity =>
      compareStrToInputValue(opportunity.mortgagorFirstName) ||
      compareStrToInputValue(opportunity.mortgagorLastName) ||
      compareStrToInputValue(opportunity.mortgagorPhone) ||
      compareStrToInputValue(opportunity.mortgagorEmail) ||
      compareStrToInputValue(opportunity.subtitle) ||
      compareStrToInputValue(opportunity.coMortgagorFirstName) ||
      compareStrToInputValue(opportunity.coMortgagorLastName) ||
      compareStrToInputValue(`${opportunity.mortgagorFirstName} ${opportunity.mortgagorLastName}`) ||
      compareStrToInputValue(`${opportunity.coMortgagorFirstName} ${opportunity.coMortgagorLastName}`)
  )

  const resetInputValue = () => setInputValue('')

  const onChange = (value: string) => {
    setInputValue(value)
  }

  const navigateToOpportunity = useCallback(
    opportunityId => navigate(generatePath(ROUTES.OPPORTUNITY, { opportunityId })),
    [navigate]
  )

  const handleSelectOpportunity = useCallback(
    (opportunity: Opportunity) => {
      track(CLICKS.opportunitiesSearchBarResult, { opportunityId: opportunity.id })
      resetInputValue()
      setSelectedOpportunity(null)
      navigateToOpportunity(opportunity.id)
    },
    [navigateToOpportunity]
  )

  useLayoutEffect(() => {
    if (selectedOpportunity) {
      handleSelectOpportunity(selectedOpportunity)
    }
  }, [selectedOpportunity, handleSelectOpportunity])

  useEffect(() => {
    setOpen(!!inputValue)
  }, [inputValue])

  const slicedOpportunities = filteredOpportunities.slice(0, 5)

  return (
    <div>
      <TextInput
        className="textInput--small min-w-[300px]"
        Icon={MagnifyingGlassIcon}
        placeholder={t('search_opportunity')}
        value={inputValue}
        onChange={onChange}
      />
      {open && (
        <Container>
          {slicedOpportunities.length ? (
            <span className="font-semibold truncate">{t('customer_folder')}</span>
          ) : (
            t('no_correspondance')
          )}
          {slicedOpportunities.map(opportunity => (
            <SearchBarOverlayItem
              key={opportunity.id}
              onClick={() => {
                setSelectedOpportunity(opportunity)
              }}
            >
              <div className="flex mt-5 cursor-pointer gap-2" onClick={() => handleSelectOpportunity(opportunity)}>
                <HighlightedIcon
                  Icon={FolderIcon}
                  variant={HIGHLIGHTED_ICON_VARIANTS.YELLOW}
                  highlightIconSize={HIGHLIGHTED_ICON_SIZE.SM}
                />
                <div className="flex-col truncate">
                  <span className="block font-semibold truncate">
                    {formatFullName(opportunity.mortgagorFirstName, opportunity.mortgagorLastName)}
                  </span>
                  <span className="block text-gray-600 font-normal text-sm truncate">{opportunity.subtitle}</span>
                  <span className="block text-gray-600 font-normal text-sm truncate">{opportunity.mortgagorPhone}</span>
                  <span className="block text-gray-600 font-normal text-sm truncate">{opportunity.mortgagorEmail}</span>
                  {opportunity.hasCoMortgagor && (
                    <span className="block text-gray-600 font-normal text-xs truncate">
                      {t('co_mortgator')}:{' '}
                      {formatFullName(opportunity.coMortgagorFirstName, opportunity.coMortgagorLastName)}
                    </span>
                  )}
                </div>
              </div>
            </SearchBarOverlayItem>
          ))}
        </Container>
      )}
    </div>
  )
}
