import classNames from 'classnames'
import styled from 'styled-components'

interface ModalProps {
  isOpen: boolean
}

interface ModalContentProps {
  className?: string
  size: string
}

export enum MODAL_SIZES {
  SM = 'w-full lg:w-3/12',
  SM_FIX = 'w-full lg:max-w-[472px] lg:min-w-0', // reason: w-x/12 is a percentage, so width change with window width. According to design, it should always be the same width (max 472px)
  MD = 'w-full lg:w-4/12',
  LG = 'w-full lg:w-6/12',
  XL = 'w-full lg:w-9/12',
  XXL = 'w-full lg:w-10/12',
}

export const ModalBackdrop = styled.div.attrs(({ isOpen }: ModalProps) => ({
  className: `
    ${isOpen ? 'block' : 'hidden'} fixed overflow-x-hidden
    z-50 inset-0 bg-gray-900 bg-opacity-50`,
}))<ModalProps>``

export const ModalContainer = styled.div.attrs(({ isOpen }: ModalProps) => ({
  className: `fixed z-[10000] inset-4 ${isOpen ? 'flex' : 'hidden'} justify-center items-center`,
}))<ModalProps>``

export const ModalContent = styled.div.attrs(({ size, className }: ModalContentProps) => ({
  className: classNames(`bg-white rounded-lg shadow ${size} max-h-full overflow-y-auto z-[60] min-w-fit`, className),
}))<ModalContentProps>``

export const ModalHeader = styled.div.attrs({
  className: 'flex justify-between items-center p-6 rounded-t border-b border-gray-200',
})``

export const ModalBody = styled.div.attrs({
  className: 'p-6 space-y-6',
})``

export const ModalFooter = styled.div.attrs({
  className: 'flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200',
})``
