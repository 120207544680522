import { CERTIFICATES } from 'helpers/documents'
import { trackEvent, TRACKING_EVENT } from 'helpers/segment'
import React, { useCallback, useState } from 'react'
import { CertificateItem, CertificateType } from 'types/certificate'
import { t } from 'utils/i18n'

import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { Button } from 'ui/buttons/button'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Dropdown } from 'ui/dropdowns/dropdown'
import { Spinner } from 'ui/spinner/spinner'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS, track } from 'helpers/tracking'

type DocumentsCardGenerateCertificateRowProps = {
  opportunity: Opportunity
  openFeasabilityModal: () => void
  fetchDepositCertificate: ({ param }: { param: string }) => void
  fetchSimulationCertificate: ({ param }: { param: string }) => void
  isLoading: boolean
}

export const DocumentsCardGenerateCertificateRow = ({
  opportunity,
  openFeasabilityModal,
  fetchDepositCertificate,
  fetchSimulationCertificate,
  isLoading,
}: DocumentsCardGenerateCertificateRowProps) => {
  const [toggle, setToggle] = useState(false)

  const handleItemClick = useCallback(
    (item: CertificateItem) => {
      track(CLICKS.opportunityCertificateGenerate, { certificateType: item.certificateType })
      switch (item.certificateType) {
        case CertificateType.Feasability: {
          openFeasabilityModal()
          break
        }
        case CertificateType.Deposit: {
          trackEvent(TRACKING_EVENT.DOCUMENTS.DEPOSIT_CERTIFICATE_GENERATED)
          fetchDepositCertificate({ param: opportunity.id })
          break
        }
        case CertificateType.Simulation: {
          trackEvent(TRACKING_EVENT.DOCUMENTS.SIMULATION_CERTIFICATE_GENERATED)
          fetchSimulationCertificate({ param: opportunity.id })
          break
        }
        default:
          break
      }
      setToggle(false)
    },
    [fetchDepositCertificate, fetchSimulationCertificate, openFeasabilityModal, opportunity.id]
  )

  return (
    <VerticalItem>
      <div className="flex justify-between items-center">
        <p className="text-sm font-bold antialiased leading-6 text-gray-800">
          {t('opportunity.choose_certificate_label')}
        </p>
        <div className="relative">
          <Button className="button--outline button--secondary button--small" onClick={() => setToggle(!toggle)}>
            {isLoading ? <Spinner /> : <EllipsisHorizontalIcon />}
          </Button>
          {toggle && (
            <Dropdown
              className="right-0"
              isVisible={toggle}
              options={[
                CERTIFICATES.map(certificate => ({
                  type: 'text',
                  label: certificate.label,
                  value: certificate.certificateType,
                  onClick: () => handleItemClick(certificate),
                })),
              ]}
            />
          )}
        </div>
      </div>
    </VerticalItem>
  )
}
