import styled from 'styled-components'

export interface SaveBarScreenType {
  class: string
  css?: string
}

interface SaveBarProps {
  shown: boolean
  screen?: SaveBarScreenType
}

export const SAVEBAR_SCREEN = {
  opportunity: { class: 'fixed right-0 bottom-0 w-full' },
  account: { class: 'fixed right-[1rem] bottom-0', css: 'width: calc(100% - 18rem);' },
}

export const SaveBar = styled.div.attrs((p: SaveBarProps) => ({
  className: `bg-white flex z-[100]
    ${p.screen ? p.screen.class : 'bottom-0 fixed w-full right-0 left-0'}`,
}))<SaveBarProps>`
  height: 0;
  overflow: hidden;
  transition: height 0.2s linear;
  ${(p: SaveBarProps) => p.screen?.css}
  ${(p: SaveBarProps) =>
    p.shown
      ? `height: 65px;
      border-top: 1px solid #D1D5DB;`
      : `
      * {
        display: none;
      }`}
  & > *:first-child {
    margin-left: auto;
  }
  & > * {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`
