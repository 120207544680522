import { DropOpportunityForm } from 'components/opportunity/dropOpportunityModal/dropOpportunityForm'
import { getCompetitors } from 'helpers/competitors'
import { MORTGAGE_OPPORTUNITY_LOSS_REASONS } from 'helpers/dropMortgageOpportunity'
import { ENDPOINTS } from 'helpers/endpoints'
import { trackEvent, TRACKING_EVENT } from 'helpers/segment'
import { useFetchApi } from 'hooks/api.hook'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Opportunity } from 'lib/oleenApi'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { useFormContext } from 'ui/forms/form'
import { ErrorMessage } from 'ui/legacy/atoms/errorMessage/errorMessage'
import { Modal } from 'ui/modals/modal'
import { useQueryClient } from '@tanstack/react-query'

enum LOSE_OPPORTUNITY_API_ERRORS {
  STATUS_MISSING_COMPETITOR_NAME = 'STATUS_MISSING_COMPETITOR_NAME',
}

const API_ERRORS_MAP = {
  [LOSE_OPPORTUNITY_API_ERRORS.STATUS_MISSING_COMPETITOR_NAME]: t(
    `opportunity.drop_opportunity_modal.errors.${LOSE_OPPORTUNITY_API_ERRORS.STATUS_MISSING_COMPETITOR_NAME}`
  ),
}

interface DropModalProps {
  closeModal: () => void
  opportunityId: Opportunity['id']
}

export const DropOpportunityModal: React.FC<DropModalProps> = ({ closeModal, opportunityId }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    error,
    fetchApi: fetchLoseOpportunity,
    loading,
  } = useFetchApi<unknown, LOSE_OPPORTUNITY_API_ERRORS>(ENDPOINTS.LOSE_OPPORTUNITY)

  const competitors = getCompetitors()

  const { getFormData, isFormValid } = useFormContext()

  const submit = useCallback(async () => {
    const payload = getFormData() // FIXME: type me

    const data = await fetchLoseOpportunity({ param: opportunityId, payload })

    if (data) {
      trackEvent(TRACKING_EVENT.OPPORTUNITY.LOST)
      // TODO: centralize query key management to facilitate invalidation
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunityId] })
      // TODO: centralize query key management to facilitate invalidation
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
    }

    const shouldSuggestInsurance =
      payload.reason === MORTGAGE_OPPORTUNITY_LOSS_REASONS.BANK_COMPETITOR ||
      payload.reason === MORTGAGE_OPPORTUNITY_LOSS_REASONS.BROKER_COMPETITOR

    if (shouldSuggestInsurance) {
      return navigate(`${ROUTES.BACK}/${ROUTES.OPPORTUNITY_INSURANCE}`)
    }

    navigate(ROUTES.BACK)
  }, [fetchLoseOpportunity, getFormData, opportunityId, queryClient, navigate])

  return (
    <Modal
      className="modal--form md:w-[450px]"
      isOpen={true}
      onClose={closeModal}
      title={t('opportunity.drop_opportunity_modal.title')}
    >
      <div>
        {competitors && <DropOpportunityForm competitors={competitors} />}
        {error && (
          <div className="my-4">
            <ErrorMessage message={API_ERRORS_MAP[error]} />
          </div>
        )}

        <Button
          className="button--solid button--danger button--small"
          onClick={submit}
          disabled={!isFormValid}
          isLoading={loading}
        >
          {t('opportunity.drop_opportunity_modal.submit')}
        </Button>
      </div>
    </Modal>
  )
}
