import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { DuplicateOpportunityResponse, ENDPOINTS } from 'helpers/endpoints'
import { trackEvent, TRACKING_EVENT } from 'helpers/segment'
import { useFetchApi } from 'hooks/api.hook'
import React from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { HighlightedIcon } from 'ui/legacy/atoms/highlightedIcon/highlightedIcon'
import { HIGHLIGHTED_ICON_VARIANTS } from 'ui/legacy/atoms/highlightedIcon/highlightedIcon.types'
import { Modal } from 'ui/legacy/atoms/modals/modal'
import { MODAL_SIZES } from 'ui/legacy/atoms/modals/modal.styled'
import { Opportunity } from 'lib/oleenApi'
import { useQueryClient } from '@tanstack/react-query'

interface DuplicateModalProps {
  isOpen: boolean
  closeModal: () => void
  closeOpportunity: () => void
  opportunityId: Opportunity['id']
}

export const DuplicateModal: React.FC<DuplicateModalProps> = ({
  isOpen,
  closeModal,
  opportunityId,
  closeOpportunity,
}) => {
  const queryClient = useQueryClient()

  const { fetchApi: duplicateOpportunity, loading } = useFetchApi<DuplicateOpportunityResponse>(
    ENDPOINTS.DUPLICATE_OPPORTUNITY
  )

  const payload = {
    opportunity_id: opportunityId,
  }

  const submit = async () => {
    const data = await duplicateOpportunity({ param: opportunityId, payload })
    if (data) {
      trackEvent(TRACKING_EVENT.OPPORTUNITY.CREATED)
      // TODO: centralize query key management to facilitate invalidation
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
    }
    closeModal()
    closeOpportunity()
  }

  return (
    <Modal
      size={MODAL_SIZES.MD}
      isOpen={isOpen}
      closeModal={closeModal}
      header={
        <div className="flex items-center text-sm pr-4 gap-2">
          <HighlightedIcon variant={HIGHLIGHTED_ICON_VARIANTS.RED} Icon={ExclamationTriangleIcon} />
          <span className="font-bold text-gray-800">{t('opportunity.duplicate_modal.title')}</span>
        </div>
      }
      footer={
        <Button className="button--solid button--danger button--small" onClick={submit} disabled={loading}>
          {t('opportunity.duplicate_modal.submit')}
        </Button>
      }
    >
      <div className="text-sm text-gray-900">{t('opportunity.duplicate_modal.info')}</div>
    </Modal>
  )
}
