import { Client } from '../client'
import { parseOpportunity } from './mortgageOpportunitiesService.parsers'
import { Opportunity, OpportunityResponse } from './mortgageOpportunitiesService.types'

export * from './mortgageOpportunitiesService.types'

declare module '../client' {
  interface Client {
    mortgageOpportunities: () => MortgageOpportunitiesService
  }
}

Client.prototype.mortgageOpportunities = function () {
  return new MortgageOpportunitiesService(this)
}

/**
 * Represents the status of an invitation.
 */
export enum OpportunityStatus {}

/**
 * Represents the service for managing mortgageOpportunities.
 */
interface IMortgageOpportunitiesService {
  listAll: () => Promise<Opportunity[]>
  get: (id: string) => Promise<Opportunity>
}

class MortgageOpportunitiesService implements IMortgageOpportunitiesService {
  constructor(private client: Client) {}

  /**
   * Lists all mortgageOpportunities.
   */
  listAll = async (): Promise<Opportunity[]> => {
    return this.client
      .getPaginatedData<OpportunityResponse, Opportunity>('/mortgage/opportunities', 1, 5000, parseOpportunity)
      .then(response => response.items)
  }

  /**
   * Gets a mortgageOpportunity by id.
   */
  get = (id: string): Promise<Opportunity> => {
    return this.client.request<OpportunityResponse, Opportunity>(
      {
        method: 'GET',
        url: `/mortgage/opportunities/${id}`,
      },
      response => parseOpportunity(response.data)
    )
  }
}
