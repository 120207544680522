import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { PropsWithChildren, ReactNode } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { ICON_BUTTON_VARIANTS } from 'ui/buttons/button.styled'
import { IconButton } from 'ui/buttons/iconButton'

import {
  MODAL_SIZES,
  ModalBackdrop,
  ModalBody,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from './modal.styled'

interface IModalProps {
  className?: string
  size: MODAL_SIZES
  header?: ReactNode
  footer?: ReactNode
  closeModal: () => void
  isOpen: boolean
}

export const Modal: React.FC<PropsWithChildren<IModalProps>> = ({
  className,
  size,
  header,
  footer,
  isOpen,
  closeModal,
  children,
}) => {
  return (
    <>
      <ModalBackdrop isOpen={isOpen} onClick={closeModal} />
      <ModalContainer isOpen={isOpen}>
        <ModalContent className={className} size={size}>
          {header ? (
            <>
              <ModalHeader>
                {header}
                <IconButton variant={ICON_BUTTON_VARIANTS.GRAY} Icon={XMarkIcon} onClick={closeModal} />
              </ModalHeader>
              <ModalBody>{children}</ModalBody>
            </>
          ) : (
            <>
              <ModalBody>
                <div className="flex flex-row-reverse shrink-0 w-full">
                  <IconButton variant={ICON_BUTTON_VARIANTS.GRAY} Icon={XMarkIcon} onClick={closeModal} />
                </div>
                {children}
              </ModalBody>
            </>
          )}
          {footer && (
            <ModalFooter>
              <Button className="button--outline button--secondary button--small" onClick={closeModal}>
                {t('modal_cancel')}
              </Button>
              <div className="flex gap-2">{footer}</div>
            </ModalFooter>
          )}
        </ModalContent>
      </ModalContainer>
    </>
  )
}
