import React, { useEffect, useState } from 'react'
import { t } from 'utils/i18n'

import { Loader as LoaderWrapper } from './loader.styled'

export const Loader: React.FC = () => {
  const DELAY = 500 // ms
  const [threeDots, setThreeDots] = useState('')

  useEffect(() => {
    const timeOut = setTimeout(() => {
      switch (threeDots) {
        case '...':
          setThreeDots('')
          break
        case '..':
          setThreeDots('...')
          break
        case '.':
          setThreeDots('..')
          break
        case '':
          setThreeDots('.')
          break
        default:
      }
    }, DELAY)

    return () => {
      if (timeOut) {
        clearTimeout(timeOut)
      }
    }
  }, [threeDots])

  return (
    <LoaderWrapper>
      <div className="text-gray-500 mt-10">
        {t('loading_msg')}
        {threeDots}
      </div>
    </LoaderWrapper>
  )
}
