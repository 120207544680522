import React from 'react'

import classnames from 'classnames'

export enum LinkCardIconColor {
  GREEN = 'text-[#0694A2] bg-[#D5F5F6]',
  BLUE = 'text-[#1C64F2] bg-[#E1EFFE]',
  RED = 'text-[#F43F5E] bg-[#FFE4E6]',
  YELLOW = 'text-[#F59E0B] bg-[#FEF3C7]',
  PURPLE = 'text-[#8B5CF6] bg-[#EDE9FE]',
  GREY = 'text-[#6B7280] bg-[#F3F4F6]',
  DARK_GREEN = 'text-[#115E59] bg-[#CCFBF1]',
}

export type LinkCardProps = {
  label: string
  description?: string
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  url: string
  badgeColor: LinkCardIconColor
}

export const LinkCard = ({ Icon, label, url, badgeColor, description }: LinkCardProps) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    className="antialiased p-4 gap-4 w-full w-full bg-white border border-gray-200 rounded-lg flex justify-start items-start"
  >
    <div className={classnames(badgeColor, 'p-3 rounded-lg')}>
      <Icon width={24} />
    </div>
    <div>
      <p className="font-bold text-gray-800">{label}</p>
      <p className="text-xs font-ligth text-gray-500">{description}</p>
    </div>
  </a>
)
