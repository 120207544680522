import { useRepresentativeContext } from 'contexts/representative'
import { validateOpportunityState } from 'helpers/consultancyContract'
import { ConsultancyContractFormData, ConsultancyContractFormFields } from 'helpers/documents'
import { errorHandlerMode } from 'helpers/errorHandler'
import React, { useCallback, useMemo } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { useFormContext } from 'ui/forms/form'
import { FormInput } from 'ui/forms/formInput'
import { Modal } from 'ui/modals/modal'

import { SendConsultancyContractInfos } from './sendConsultancyContractInfos'
import { SendConsultancyContractToast } from './sendConsultancyContractToast'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'

export interface SendConsultancyContractModalProps {
  opportunity: Opportunity
  closeModal: () => void
  fetchApi: ({ param, payload }: { param: string; payload: Record<string, string | null> }) => Promise<unknown>
  isLoading: boolean
}

export const SendConsultancyContractModal: React.FC<SendConsultancyContractModalProps> = ({
  opportunity,
  closeModal,
  fetchApi,
  isLoading,
}) => {
  const { getFormData, isFormValid } = useFormContext()
  const { representative } = useRepresentativeContext()

  const onSendConsultancyContract = useCallback(async () => {
    const payload = getFormData() as ConsultancyContractFormData
    await fetchApi({ param: opportunity.id, payload })
    closeModal()
  }, [closeModal, fetchApi, getFormData, opportunity.id])

  const { isValid, missingFields } = useMemo(
    () => validateOpportunityState(opportunity, representative),
    [opportunity, representative]
  )

  return (
    <Modal
      className="modal--form md:!w-9/12"
      isOpen={true}
      onClose={closeModal}
      title={t('opportunity.consultancy_modal.title')}
    >
      <div className="flex flex-col gap-4">
        <SendConsultancyContractInfos opportunity={opportunity} />
        <div className="w-full text-xs font-normal leading-5 flex justify-between">
          <FormInput
            name={ConsultancyContractFormFields.fees}
            label={t('opportunity.consultancy_modal.consultancy_contract_fee_label')}
            className="w-fit"
            validations={[{ type: errorHandlerMode.NON_NULLABLE }, { type: errorHandlerMode.NUMBER }]}
          />
          <FormInput
            type="select"
            name={ConsultancyContractFormFields.template}
            label={t('opportunity.consultancy_modal.consultancy_contract_template_label')}
            className="w-fit"
            defaultValue="standard"
            options={[
              [
                { type: 'text', value: 'standard', label: 'Standard' },
                { type: 'text', value: 'custom', label: 'Personnalisé' },
              ],
            ]}
          />
        </div>
        <SendConsultancyContractToast opportunity={opportunity} isValid={isValid} missingFields={missingFields} />
      </div>
      <footer className="px-8 pb-4 flex justify-between">
        <Button className="button--outline button--secondary button--small" onClick={closeModal}>
          {t('modal_cancel')}
        </Button>
        <Button
          className="button--solid button--primary button--small"
          tracking={[CLICKS.opportunityConsultancyContractSend]}
          onClick={onSendConsultancyContract}
          disabled={!isFormValid || !isValid}
          isLoading={isLoading}
        >
          {t('opportunity.consultancy_modal.send')}
        </Button>
      </footer>
    </Modal>
  )
}
