import classNames from 'classnames'
import { ErrorHandler } from 'helpers/errorHandler'
import { id } from 'helpers/ref'
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'

import { useField, UseFieldProps } from 'ui/forms/form.hook'
import { FormAutocompleteInput } from 'ui/forms/formInput/formAutocompleteInput'
import { FormCheckBoxToggle } from 'ui/forms/formInput/formCheckBoxToggle'
import { FormDateInput } from 'ui/forms/formInput/formDateInput'
import { FormPhoneInput } from 'ui/forms/formInput/formPhoneInput'
import { FormRadio } from 'ui/forms/formInput/formRadio'
import { FormSelectInput, FormSelectInputCustomProps, FormSelectInputProps } from 'ui/forms/formInput/formSelectInput'
import { FormTextInput } from 'ui/forms/formInput/formTextInput'
import { FormTextArea } from 'ui/forms/formTextArea/formTextArea'

import { FormSelectBadgeInput } from './formInput/formSelectBadgeInput'

export interface BaseFormInputProps extends UseFieldProps {
  caption?: string
  className?: string
  disabled?: boolean
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  id?: string
  label?: string
  placeholder?: string
  // When the user submits a form and the error message is the same, the error
  // JSX will not re-render. Thus, a unique id is then necessary to force the
  // re-rendering to happen
  error?: { id: string; value: string }
}

type FormInputTypeProps =
  | { type?: 'text'; textInputClassName?: string; enableClear?: boolean }
  | { type: 'textArea'; textAreaClassName?: string }
  | { type: 'date'; dateInputClassName?: string }
  | { type: 'phone' }
  | { type: 'checkBoxToggle' }
  | ({ type: 'selectBadge'; options?: FormSelectInputProps['options'] } & FormSelectInputCustomProps)
  | ({
      type: 'select'
      selectClassName?: string
      options?: FormSelectInputProps['options']
    } & FormSelectInputCustomProps)
  | { type: 'autocomplete'; items: string[]; className?: string; dropdownClassName?: string }
  | { type: 'radio'; radioOptions: { value: string; label: string }[]; className?: string; radioClassName?: string }

type FormInputProps = BaseFormInputProps & FormInputTypeProps

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    { caption, className, defaultValue, onChange, onBlur, label, name, type = 'text', validations, error, ...props },
    ref
  ) => {
    const inputId = props.id || id()
    const [innerError, setInnerError] = useState<string | undefined>(error?.value)

    useEffect(() => setInnerError(error?.value), [setInnerError, error])

    const errorHandler = useMemo(() => {
      if (!validations) return undefined
      return new ErrorHandler({ validations })
    }, [validations])

    const {
      handleFieldChange,
      handleFieldBlur,
      value,
      error: validationError,
    } = useField({
      name,
      errorHandler,
      defaultValue,
      onBlur,
      onChange,
    })

    const handleBlur = useCallback(
      (value?: string | boolean | number) => {
        setInnerError(undefined)
        handleFieldBlur(value as string)
      },
      [setInnerError, handleFieldBlur]
    )

    const fieldProps = {
      name,
      id: inputId,
      error: innerError || validationError,
      handleFieldChange,
      handleFieldBlur: handleBlur,
      ref,
      value,
      options: 'options' in props && props.options ? props.options : [],
      items: 'items' in props && props.items ? props.items : [],
      radioOptions: 'radioOptions' in props && props.radioOptions ? props.radioOptions : [],
      ...props,
    }

    const componentMapping = {
      autocomplete: <FormAutocompleteInput {...fieldProps} />,
      text: <FormTextInput {...fieldProps} />,
      textArea: <FormTextArea {...fieldProps} />,
      date: <FormDateInput {...fieldProps} />,
      phone: <FormPhoneInput {...fieldProps} />,
      select: <FormSelectInput {...fieldProps} />,
      selectBadge: <FormSelectBadgeInput {...fieldProps} />,
      radio: <FormRadio {...fieldProps} />,
      checkBoxToggle: <FormCheckBoxToggle {...fieldProps} />,
    }

    const Component = componentMapping[type]

    return (
      <div className={classNames(className, 'formInput')}>
        {label && <label htmlFor={inputId}>{label}</label>}

        {Component}

        {validationError && <p className="caption caption--error">{validationError}</p>}
        {innerError && <p className="caption caption--error">{innerError}</p>}
        {caption && <p className="caption">{caption}</p>}
      </div>
    )
  }
)
FormInput.displayName = 'FormInput'
