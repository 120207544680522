import {
  EditBusinessPartnerForm,
  EditBusinessPartnerFormData,
  EditBusinessPartnerFormError,
} from 'components/businessPartners/editBusinessPartnerForm'
import { useBusinessPartners } from 'contexts/businessPartners'
import { useLeadContributors } from 'contexts/leadContributors'
import { useOleenApi } from 'contexts/oleenApi'
import { toast } from 'helpers/toast'
import { useAsync } from 'hooks/async.hook'
import { BusinessPartner, BusinessPartnerApiError } from 'lib/oleenApi'
import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { FormProvider, useFormContext } from 'ui/forms/form'
import { Modal } from 'ui/legacy/atoms/modals/modal'
import { MODAL_SIZES } from 'ui/legacy/atoms/modals/modal.styled'
import { CLICKS } from 'helpers/tracking'

type EditBusinessPartnerModalContentProps = {
  businessPartner: BusinessPartner
  closeModal: () => void
}

const EditBusinessPartnerModalContent: React.FC<EditBusinessPartnerModalContentProps> = ({
  businessPartner,
  closeModal,
}) => {
  const { getFormData, isFormValid } = useFormContext()
  const { oleenApi } = useOleenApi()
  const [formError, setFormError] = useState<EditBusinessPartnerFormError>({})
  const { refreshBusinessPartners } = useBusinessPartners()
  const { refreshLeadContributors } = useLeadContributors()

  const editBusinessPartner = useCallback(() => {
    const businessPartnerData = getFormData() as EditBusinessPartnerFormData
    return oleenApi.businessPartners().update(businessPartner.id, businessPartnerData)
  }, [oleenApi, getFormData, businessPartner.id])

  const { data, error, run } = useAsync(editBusinessPartner)

  useEffect(() => {
    if (!data) return

    toast.success(t('business_partners.toasts.update.success'))
    refreshBusinessPartners()
    refreshLeadContributors()
    closeModal()
  }, [data, closeModal, refreshBusinessPartners, refreshLeadContributors])

  useEffect(() => {
    if (!error) return

    toast.error(t('business_partners.toasts.update.error'))
    if (error instanceof BusinessPartnerApiError) {
      setFormError({})
      error.details.forEach(detail => {
        setFormError(prev => ({ ...prev, [detail.attribute]: detail.type }))
      })
    }
  }, [error])

  return (
    <EditBusinessPartnerForm businessPartner={businessPartner} error={formError}>
      <Button
        className="button--solid button--primary button--medium"
        disabled={!isFormValid}
        onClick={run}
        tracking={[CLICKS.businessPartnerSave]}
      >
        <span>{t('business_partners.modal.edit.submit')}</span>
      </Button>
      <Button className="button--solid button--secondary button--medium" onClick={closeModal}>
        <span>{t('common.cancel')}</span>
      </Button>
    </EditBusinessPartnerForm>
  )
}

type EditBusinessPartnerModalProps = {
  businessPartner: BusinessPartner
  isOpen: boolean
  closeModal: () => void
}

export const EditBusinessPartnerModal: React.FC<EditBusinessPartnerModalProps> = ({
  isOpen,
  closeModal,
  businessPartner,
}) => {
  if (!isOpen) return <></>

  return (
    <FormProvider>
      <Modal
        className="overflow-y-auto"
        isOpen={isOpen}
        closeModal={closeModal}
        size={MODAL_SIZES.MD}
        header={
          <div tabIndex={-1}>
            <p className="text-gray-900 text-base font-bold font-inter leading-6">
              {t('business_partners.modal.edit.title')}
            </p>
          </div>
        }
      >
        <EditBusinessPartnerModalContent businessPartner={businessPartner} closeModal={closeModal} />
      </Modal>
    </FormProvider>
  )
}
