import React from 'react'
import { Card } from 'ui/sections/card'
import { t } from 'utils/i18n'
import { toast } from 'helpers/toast'

import { Representative } from 'lib/oleenApi'
import { Button } from 'ui/buttons/button'
import { CLICKS } from 'helpers/tracking'

type AdvancedParametersCardProps = {
  representative: Representative
}

export const AdvancedParametersCard: React.FC<AdvancedParametersCardProps> = ({ representative }) => {
  const publicFormUrl = `${process.env.PUBLIC_CONTACT_FORM_URL}?external_id=${representative.externalId}`
  const IFrameSnippet = `<iframe id="oleen-contact-form" src="${publicFormUrl}" width="100%" style="border: none;"></iframe>`

  // write a function to copy the iframe code to the clipboard
  const copyIframeSnippet = () => {
    navigator.clipboard.writeText(IFrameSnippet)
    toast.success(t('account.advanced_parameters.form_contact_iframe_copied'))
  }

  return (
    <Card title={t('account.advanced_parameters.title')}>
      <div className="flex flex-col gap-2 card--content">
        <div>
          <h3 className="text-xs leading-4">{t('account.advanced_parameters.form_contact_iframe')}</h3>
          <p className="text-xs leading-4 text-gray-400">
            {t('account.advanced_parameters.form_contact_iframe_description')}
          </p>
        </div>
        <code className="relative text-white bg-black p-2 text-xs w-1/2 rounded-lg">
          <Button
            className="button--small button-gray button--outline bg-gray-200 h-6 absolute right-2"
            onClick={copyIframeSnippet}
            tracking={[CLICKS.contactIFrameCopy]}
          >
            {t('account.advanced_parameters.copy')}
          </Button>
          {IFrameSnippet}
        </code>
      </div>
    </Card>
  )
}
