import React from 'react'

import { useField } from 'ui/forms/form.hook'
import { CheckboxInput, CheckboxLabel, CheckboxRow } from 'ui/legacy/atoms/checkbox/checkbox.styled'
import { ICheckbox } from 'ui/legacy/atoms/checkbox/checkbox.types'

export const Checkbox: React.FC<ICheckbox> = ({ name, label, checked, onChange }) => {
  const { handleFieldChange, value } = useField({ name, defaultValue: checked })

  return (
    <CheckboxRow>
      <CheckboxInput
        name={name}
        checked={value as boolean}
        onChange={({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
          handleFieldChange(checked)
          onChange?.(checked)
        }}
      />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxRow>
  )
}
