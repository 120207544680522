import styled from 'styled-components'

export enum ToastVariants {
  GREEN,
  GRAY,
  ORANGE,
}

interface ToastProps {
  variant: ToastVariants
}

export const Toast = styled.div.attrs<ToastProps>(({ variant }) => {
  const baseStyles = 'flex p-2 items-center rounded-lg'

  switch (variant) {
    case ToastVariants.GREEN:
      return {
        className: `${baseStyles} bg-green-600`,
        role: 'alert',
      }
    case ToastVariants.GRAY:
      return {
        className: `${baseStyles} bg-gray-100`,
        role: 'alert',
      }
    case ToastVariants.ORANGE:
      return {
        className: `${baseStyles} bg-orange-100`,
        role: 'alert',
      }
    default:
      return null
  }
})<ToastProps>``
