import styled from 'styled-components'

interface IconButtonProps {
  variant: string
}

export const BUTTON_SIZE_VARIANTS = {
  SMALL: 'text-xs',
  DEFAULT: 'text-sm',
}

const iconButtonBaseStyles = 'rounded-sm focus:ring-2 flex justify-center items-center h-10 w-10 lg:w-6 lg:h-6'

export const ICON_BUTTON_VARIANTS = {
  GREEN: 'text-green-900 focus:ring-green-900',
  GRAY: 'text-gray-500 focus:ring-blue-300',
  DARK_BG: 'bg-gray-800 text-white',
}

export const IconButton = styled.button.attrs(({ variant }: IconButtonProps) => ({
  className: `${iconButtonBaseStyles} ${variant}`,
}))<IconButtonProps>``
