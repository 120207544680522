import { AxiosRequestConfig } from 'axios'
import { API_VERB } from 'helpers/api'

export interface Endpoint {
  path: string
  verb: API_VERB
  options?: AxiosRequestConfig
}

export type GetEndpointFunc = (param?: string) => Endpoint

export const ENDPOINTS: Record<string, GetEndpointFunc> = {
  CREATE_PROJECT: () => ({
    path: '/mortgage/create-project',
    verb: API_VERB.POST,
  }),
  CREATE_OPPORTUNITY: () => ({
    path: '/opportunity/create',
    verb: API_VERB.POST,
  }),
  CREATE_OPPORTUNITY_ASYNC: () => ({
    path: '/mortgage/opportunities',
    verb: API_VERB.POST,
  }),
  UPDATE_OPPORTUNITY: opportunityId => ({
    path: `/mortgage/opportunities/${opportunityId}`,
    verb: API_VERB.PUT,
  }),
  DUPLICATE_OPPORTUNITY: opportunityId => ({
    path: `/mortgage/opportunities/${opportunityId}/duplicate`,
    verb: API_VERB.POST,
  }),
  LOSE_OPPORTUNITY: opportunityId => ({
    path: `/mortgage/opportunities/${opportunityId}/lose`,
    verb: API_VERB.POST,
  }),
  GET_OPPORTUNITY_TOKEN: opportunityId => ({
    path: `/opportunity/${opportunityId}/token`,
    verb: API_VERB.GET,
  }),
  GENERATE_FEASABILITY_PDF: opportunityId => ({
    path: `/opportunity/${opportunityId}/certificate/feasability`,
    verb: API_VERB.POST,
    options: { responseType: 'blob' },
  }),
  GENERATE_SIMULATION_PDF: opportunityId => ({
    path: `/opportunity/${opportunityId}/certificate/simulation`,
    verb: API_VERB.POST,
    options: { responseType: 'blob' },
  }),
  GENERATE_DEPOSIT_PDF: opportunityId => ({
    path: `/opportunity/${opportunityId}/certificate/deposit`,
    verb: API_VERB.POST,
    options: { responseType: 'blob' },
  }),
  GENERATE_CONSULTANCY_CONTRACT: opportunityId => ({
    path: `/mortgage/opportunities/${opportunityId}/send-consultancy-contract`,
    verb: API_VERB.POST,
  }),
  GENERATE_MANDATE_PREVIEW: opportunityId => ({
    path: `/mortgage/opportunities/${opportunityId}/mandates/preview`,
    verb: API_VERB.POST,
  }),
  SEND_MANDATE: opportunityId => ({
    path: `/mortgage/opportunities/${opportunityId}/mandates`,
    verb: API_VERB.POST,
  }),
  CANCEL_MANDATE: mandateId => ({
    path: `/mortgage/mandates/${mandateId}`,
    verb: API_VERB.DELETE,
  }),
  GET_NOTIFICATIONS: () => ({
    path: '/notifications',
    verb: API_VERB.GET,
  }),
  CREATE_NOTIFICATION_INTERACTION: notificationId => ({
    path: `/notifications/${notificationId}/interactions`,
    verb: API_VERB.POST,
  }),
  CREATE_NOTIFICATIONS_VIEWS: () => ({
    path: `/notifications_views`,
    verb: API_VERB.POST,
  }),
}

export interface GenerateMandatePayload {
  comment: string
}

export interface GenerateMandatePreviewResponse {
  url: string
}

export type SignatureBase = {
  signed_at: string
}

export type OpportunityTokenResponse = {
  token: string
}

export interface RepresentativeResponse {
  id: string
  address: string
  brand_name: string
  company_name: string
  first_name: string
  last_name: string
  orias_id: string
  email: string
  default_brokerage_fees: string
  new_zou: boolean
}

export interface CreateOpportunityResponse {
  opportunity_id?: string | null
  project_id?: string | null
  error?: string
}

export interface DuplicateOpportunityResponse {
  opportunity_id: string
}

export type NotificationTypes =
  | 'Notification::LeadContributed'
  | 'Notification::MortgageFilesUploaded'
  | 'Notification::WebsiteOpportunityCreated'

export type BaseNotificationResponse = {
  id: string
  created_at: string
  updated_at: string
  seen_at: string | null
  interacted_at: string | null
}

export type MortgageFilesUploadedNotificationResponse = BaseNotificationResponse & {
  type: 'Notification::MortgageFilesUploaded'
  data: {
    opportunity_id: string
    files: string[]
    mortgagor: {
      first_name: string | null
      last_name: string | null
      email: string | null
      id: string | null
    }
  }
}

export const isMortgageFilesUploadedNotificationResponse = (
  notification: GetNotificationResponse
): notification is MortgageFilesUploadedNotificationResponse =>
  notification.type === 'Notification::MortgageFilesUploaded'

export type LeadContributedNotificationResponse = BaseNotificationResponse & {
  type: 'Notification::LeadContributed'
  data: {
    lead_contributor: {
      first_name: string | null
      last_name: string | null
      email: string | null
      id: string | null
    }
    contact: {
      first_name: string | null
      last_name: string | null
      email: string | null
      id: string | null
    }
  }
}

export const isLeadContributedNotificationResponse = (
  notification: GetNotificationResponse
): notification is LeadContributedNotificationResponse => notification.type === 'Notification::LeadContributed'

export type WebsiteOpportunityCreatedNotificationResponse = BaseNotificationResponse & {
  type: 'Notification::WebsiteOpportunityCreated'
  data: {
    opportunity_id: string
    mortgagor: {
      first_name: string | null
      last_name: string | null
    }
  }
}

export const isWebsiteOpportunityCreatedNotificationResponse = (
  notification: GetNotificationResponse
): notification is WebsiteOpportunityCreatedNotificationResponse =>
  notification.type === 'Notification::WebsiteOpportunityCreated'

export type OpportunityContributedNotificationResponse = BaseNotificationResponse & {
  type: 'Notification::OpportunityContributed'
  data: {
    opportunity_id: string
    mortgagor: {
      first_name: string | null
      last_name: string | null
    }
    lead_contributor: {
      first_name: string | null
      last_name: string | null
    }
  }
}

export const isOpportunityContributedNotificationResponse = (
  notification: GetNotificationResponse
): notification is OpportunityContributedNotificationResponse =>
  notification.type === 'Notification::OpportunityContributed'

export type UnknownNotificationResponse = BaseNotificationResponse & {
  type: unknown
  data: unknown
}

export type GetNotificationResponse =
  | LeadContributedNotificationResponse
  | MortgageFilesUploadedNotificationResponse
  | WebsiteOpportunityCreatedNotificationResponse
  | OpportunityContributedNotificationResponse
  | UnknownNotificationResponse

export interface GetNotificationsResponse {
  notifications: GetNotificationResponse[]
  total: number
  page: number
  per_page: number
  next_page: number | null
  prev_page: number | null
}
