import { AnalyticsBrowser } from '@segment/analytics-next'

const analytics = AnalyticsBrowser.load({ writeKey: process.env.SEGMENT_KEY || '' })

export enum EEventType {
  Page = 'page',
  Track = 'track',
  NoTracking = 'no_tracking',
  Identify = 'identify',
}

export enum EEventAction {
  Clicked = 'clicked',
  Dragged = 'dragged',
  Hover = 'hover',
  NoTracking = 'no_tracking',
  Toggled = 'toggled',
  Viewed = 'viewed',
}

export interface IEvent {
  object: string
  action: EEventAction
  type: EEventType
}

export const PAGES = {
  account: {
    object: 'account',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  addonSubscription: {
    object: 'addon-subscription',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  contacts: {
    object: 'contacts',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  contactCreation: {
    object: 'contact-creation',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  leadContributors: {
    object: 'lead-contributors',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunities: {
    object: 'opportunities',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunityCreation: {
    object: 'opportunity-creation',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunity: {
    object: 'opportunity',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  // naming ?
  opportunityConsultancyContract: {
    object: 'opportunity-feasibility-certificate',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunityDrop: {
    object: 'opportunity-dropping',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunityEmails: {
    object: 'opportunity-emails',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunityFeasabilityCertificate: {
    object: 'opportunity-feasibility-certificate',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunityInsurance: {
    object: 'opportunity-insurance',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunityMandate: {
    object: 'opportunity-mandate',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  opportunityTaskCreation: {
    object: 'opportunity-task-creation',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  organization: {
    object: 'organization',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  productionDashboard: {
    object: 'production-dashboard',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  roadmap: {
    object: 'roadmap',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  tasks: {
    object: 'tasks',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  task: {
    object: 'task',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  taskCreation: {
    object: 'task-creation',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  taskEdition: {
    object: 'task-edition',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  taskToCalendarExport: {
    object: 'task-to-calendar-export',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  usefullLinks: {
    object: 'usefull-links',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
}

export const CLICKS = {
  logout: {
    object: 'logout',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  contactIFrameCopy: {
    object: 'contact-iframe-copied',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  addonSubscribe: {
    object: 'addon-subsrcibe',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  addonSubscriptionConfirm: {
    object: 'addon-subscription-confirm',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  addonSubscriptionReject: {
    object: 'addon-subscription-reject',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bosHelp: {
    object: 'bos-callout-help',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bosCalloutDetails: {
    object: 'bos-callout-details',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  contactCreate: {
    object: 'contact-create',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  contactSave: {
    object: 'contact-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  contactArchive: {
    object: 'contact-archive',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityCreate: {
    object: 'opportunity-create',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunitiesViewMode: {
    object: 'opportunities-view-mode',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunitiesFilter: {
    object: 'opportunities-filter',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunitiesSearchBarResult: {
    object: 'opportunities-search-bar-result',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunitiesRevenueShow: {
    object: 'opportunities-revenue-show',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityEmailModels: {
    object: 'opportunity-email-models',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityEmailModelGenerate: {
    object: 'opportunity-email-model-generate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityEmailModelBodyCopy: {
    object: 'opportunity-email-model-content-copy',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityEmailModelSubjectCopy: {
    object: 'opportunity-email-model-subject-copy',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityOpenZou: {
    object: 'opportunity-open-zou',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityOpenZouInterrogations: {
    object: 'opportunity-open-zou-interrogations',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityOpenZouBankResponses: {
    object: 'opportunity-open-zou-bank-responses',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityUserSpaceUrlCopy: {
    object: 'opportunity-user-space-url-copy',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityOpenUserSpace: {
    object: 'opportunity-open-user-space',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityAddLeadContributor: {
    object: 'opportunity-add-lead-contributor',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityNewTask: {
    object: 'opportunity-new-task',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityOpenStorage: {
    object: 'opportunity-open-storage',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityMandateSend: {
    object: 'opportunity-mandate-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityMandateConfirmSend: {
    object: 'opportunity-mandate-confirm-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityMandatePreview: {
    object: 'opportunity-mandate-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityMandateCancel: {
    object: 'opportunity-mandate-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityDEREmailSend: {
    object: 'opportunity-der-email-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityConsultancyContractSend: {
    object: 'opportunity-consultancy-contract-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityCertificateGenerate: {
    object: 'opportunity-feasability-certificate-generate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityFeasabilityCertificateGenerate: {
    object: 'opportunity-feasability-certificate-generate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityDuplicate: {
    object: 'opportunity-duplicate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  opportunityDrop: {
    object: 'opportunity-drop',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  openBOSModal: {
    object: 'open-bos-modal',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  submitBosForm: {
    object: 'opportunity-bos-form-submit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  skipBosForm: {
    object: 'opportunity-bos-form-skip',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  leadContributorAdd: {
    object: 'lead-contributors-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  leadContributorEdit: {
    object: 'lead-contributors-edit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  leadContributorSave: {
    object: 'lead-contributors-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  businessPartnerAdd: {
    object: 'business-partner-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  businessPartnerEdit: {
    object: 'business-partner-edit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  businessPartnerSave: {
    object: 'business-partner-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationsMenu: {
    object: 'notifications-menu',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notification: {
    object: 'notification',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  taskCreate: {
    object: 'task-create',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  tasksFilter: {
    object: 'tasks-filter',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  taskExport: {
    object: 'task-export',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  taskEdit: {
    object: 'task-edit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  taskSave: {
    object: 'task-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  taskExportType: {
    object: 'task-export-type',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  taskComplete: {
    object: 'task-complete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  navbarAccount: {
    object: 'navbar-account',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
}

export const track = (event: IEvent, props?: Record<string, string | string[] | boolean | null | undefined>): void => {
  if (event.type !== EEventType.NoTracking) {
    const eventProperties = {
      ...props,
    }

    if (process.env.NODE_ENV === 'production') {
      analytics[event.type](`${event.object}:${event.action}`, eventProperties)
    }
  }
}

export const trackUser = (userReference: string, options?: object | undefined) => {
  const enhancedOptions = { email: userReference, ...options }
  analytics.identify(userReference, enhancedOptions)
}

export type Tracked = {
  tracking: Parameters<typeof track>
}
