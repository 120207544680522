import { OPPORTUNITY_STATUS } from 'lib/oleenApi'
import { CSSProperties } from 'styled-components'

export interface TooltipSumCategoryProps {
  ref?: (node: HTMLElement | null) => void
  style?: CSSProperties
  title: OPPORTUNITY_STATUS
  items: Array<StackedBarItem>
  className?: string
}

export enum Fees {
  bankCommission = 'bankCommission',
  brokerageFees = 'brokerageFees',
}

export type StackedBarItem = {
  label: Fees
  amount: number
}

export enum FeesColors {
  default = '#E5E7EB',
  bankCommission = '#16BDCA',
  brokerageFees = '#5850EC',
}

export type FeeColorPillProps = {
  color: string
}

export type StackedBarProps = {
  isFirst: boolean
  isLast: boolean
  color: string
  share: number
}

export type TooltipStackedBarProps = {
  items: StackedBarItem[]
}

export type TooltipDetailsProps = {
  items: StackedBarItem[]
}
