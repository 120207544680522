import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { I18NPath, t } from 'utils/i18n'

import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { Opportunity } from 'lib/oleenApi'
import { Badge } from 'ui/badges/badge'
import { useRepresentativeContext } from 'contexts/representative'
import classNames from 'classnames'
import { CLICKS, track } from 'helpers/tracking'
import { useFetchApi } from 'hooks/api.hook'
import { ENDPOINTS } from 'helpers/endpoints'
import { useMutation } from '@tanstack/react-query'
import { sendDERQuery } from 'helpers/queries'

interface DocumentsCardDERRowProps {
  opportunity: Opportunity
}

const derRequiredInfos = ['firstName', 'lastName', 'companyName', 'address', 'siret', 'oriasId', 'phone']
// TODO: This enum should be used in the opportunity type and backend should manage UNAVAILABLE and NOT_SENT states
enum DER_STATE {
  NOT_SENT = 'not_sent',
  UNAVAILABLE = 'unavailable',
  PENDING = 'pending',
  SUCCESS = 'delivered',
  FAILED = 'failed',
}

const sendButtonTexts = {
  [DER_STATE.NOT_SENT]: t('opportunity.der_row.send_button.send_now'),
  [DER_STATE.SUCCESS]: t('opportunity.der_row.send_button.resend'),
  [DER_STATE.FAILED]: t('opportunity.der_row.send_button.retry'),
}

export const DocumentsCardDERRow: React.FC<DocumentsCardDERRowProps> = ({ opportunity }) => {
  const { representative } = useRepresentativeContext()
  const initialDerState = representative?.isDerInfosPresent
    ? opportunity?.derEmailStatus
      ? (opportunity?.derEmailStatus as DER_STATE)
      : DER_STATE.NOT_SENT
    : DER_STATE.UNAVAILABLE
  const [derState, setDerState] = React.useState<DER_STATE>(initialDerState)
  const badgeText = t(`opportunity.der_row.badge_state.${derState}` as I18NPath)
  const missingInfos = derRequiredInfos.filter(info => !representative?.[info as keyof typeof representative])
  const badgeColor = {
    'badge--gray': derState === DER_STATE.PENDING || derState === DER_STATE.NOT_SENT,
    'badge--orange': derState === DER_STATE.UNAVAILABLE,
    'badge--green': derState === DER_STATE.SUCCESS,
    'badge--red': derState === DER_STATE.FAILED,
  }
  const descriptionTextColor = {
    'text-gray-500':
      derState === DER_STATE.SUCCESS || derState === DER_STATE.PENDING || derState === DER_STATE.NOT_SENT,
    'text-orange-600': derState === DER_STATE.UNAVAILABLE,
    'text-red-600': derState === DER_STATE.FAILED,
  }
  const newDERMutation = useMutation({
    mutationFn: sendDERQuery,
    onError: () => {
      setDerState(DER_STATE.NOT_SENT)
    },
  })
  const sendDER = () => {
    track(CLICKS.opportunityDEREmailSend, { opportunityId: opportunity.id })
    setDerState(DER_STATE.PENDING)
    newDERMutation.mutateAsync(opportunity.id)
  }

  return (
    <VerticalItem>
      <div className="flex justify-between items-center gap-6">
        <div className="flex flex-col items-start">
          <p className="text-sm font-bold antialiased leading-6 text-gray-800">{t('opportunity.der_row.title')}</p>
          <div className={classNames(descriptionTextColor, 'flex gap-1 text-xs font-normal antialiased')}>
            {derState === DER_STATE.UNAVAILABLE ? (
              <ExclamationTriangleIcon className="w-5 h-5 shrink-0 self-start" />
            ) : (
              <InformationCircleIcon className="w-5 h-5 shrink-0 self-start" />
            )}
            <div className="self-end">
              {t(`opportunity.der_row.state_infos.${derState}` as I18NPath)}
              &nbsp;
              {derState === DER_STATE.UNAVAILABLE && (
                <ul className="list-disc ml-5">
                  {missingInfos.map(info => (
                    <li key={info}>{t(`opportunity.der_row.missing_infos.${info}` as I18NPath)}</li>
                  ))}
                </ul>
              )}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-xs font-normal antialiased underline"
                href={t('opportunity.der_row.help.link')}
              >
                {t('opportunity.der_row.help.text')}
              </a>
            </div>
          </div>
          {derState !== DER_STATE.UNAVAILABLE && derState !== DER_STATE.PENDING && (
            <a className="text-royalBlue-600 mt-2 cursor-pointer" onClick={sendDER}>
              {sendButtonTexts[derState]}
            </a>
          )}
        </div>
        <div className="flex items-center">
          <Badge className={classNames(badgeColor, 'badge--default text-xs')}>{badgeText}</Badge>
        </div>
      </div>
    </VerticalItem>
  )
}
