import styled from 'styled-components'

import { FeeColorPillProps, StackedBarProps } from './tooltipSumCategory.types'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const StackedBarWrapper = styled.div`
  display: flex;
`

export const StackedBarItem = styled.div<StackedBarProps>`
  width: ${({ share }) => `${share}%`};
  border: ${({ color }) => `2px solid ${color}`};
  border-top-left-radius: ${({ isFirst }) => (isFirst ? '24px' : '0px')};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? '24px' : '0px')};
  border-top-right-radius: ${({ isLast }) => (isLast ? '24px' : '0px')};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? '24px' : '0px')};
`

export const TooltipDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -12px;
  gap: 96px;
`

export const FeeColorPill = styled.div<FeeColorPillProps>`
  min-width: 10px;
  height: 10px;
  margin: 0 8px 0 0;
  background-color: ${({ color }) => color};
`

export const TooltipLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
