import styled from 'styled-components'

interface DotIconProps {
  disabled?: boolean
  active: boolean
}

// FIXME disabled is not handled
export const DotIcon = styled.div.attrs(({ active }: DotIconProps) => ({
  className: `
     h-1.5 w-1.5 rounded-lg bg-gray-600 box-content border-2
     ${active ? 'border-gray-400 bg-gray-50' : 'border-gray-800 group-hover:border-gray-600 group-hover:bg-gray-400'}
  `,
}))<DotIconProps>``

export const getClassName = (disabled?: boolean, active?: boolean, highlight?: boolean) => `
  group flex rounded-lg px-3 h-fit py-2  text-sm whitespace-nowrap justify-between antialiased
  ${disabled ? 'text-gray-600' : 'text-white hover:bg-gray-600'}
  ${highlight || active ? 'font-medium' : 'font-light'}
  ${active ? 'bg-gray-600' : ''}
   `

interface Props {
  disabled?: boolean
  active?: boolean
  highlight?: boolean
}

export const StyledA = styled.a.attrs((p: Props) => ({
  className: getClassName(p.disabled, p.active, p.highlight),
}))<Props>``

export const StyledDiv = styled.div.attrs((p: Props) => ({
  className: getClassName(p.disabled, p.active, p.highlight),
}))<Props>``
