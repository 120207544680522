import { parseDate, parseNullableDate } from 'helpers/date'
import {
  GetNotificationResponse,
  GetNotificationsResponse,
  isLeadContributedNotificationResponse,
  isMortgageFilesUploadedNotificationResponse,
  isOpportunityContributedNotificationResponse,
  isWebsiteOpportunityCreatedNotificationResponse,
  LeadContributedNotificationResponse,
  MortgageFilesUploadedNotificationResponse,
  OpportunityContributedNotificationResponse,
  WebsiteOpportunityCreatedNotificationResponse,
} from 'helpers/endpoints'
import { EMPTY_SIGN, formatDateWithTime } from 'helpers/format'
import { Notification, Notifications } from 'types/notification'
import { t } from 'utils/i18n'
import Sentry from 'utils/sentry'

export const formatTiming = (date?: Date | null) => {
  if (!date) return EMPTY_SIGN

  const minute = 60 * 1000
  const oneMinuteAgo = new Date(Date.now() - minute)

  if (date > oneMinuteAgo) {
    return t('notification.just_now')
  }
  return formatDateWithTime(date)
}

type GetNotificationQuery = {
  page?: number
  unseen?: boolean
}

export const buildGetNotificationsQuery = ({ page, unseen }: GetNotificationQuery): URLSearchParams => {
  const params = new URLSearchParams()
  if (page) params.append('page', page.toString())
  if (unseen) params.append('unseen', true.toString())
  return params
}

export const parseNotifications = (notificationsResponse: GetNotificationsResponse): Notifications => {
  return {
    notifications: notificationsResponse.notifications.map(parseNotification).filter(Boolean) as Notification[],
    total: notificationsResponse.total,
    page: notificationsResponse.page,
    perPage: notificationsResponse.per_page,
    nextPage: notificationsResponse.next_page,
    prevPage: notificationsResponse.prev_page,
  }
}

export const parseBaseNotification = (
  notificationResponse: GetNotificationResponse
): Omit<Notification, 'data' | '_type'> => {
  return {
    id: notificationResponse.id,
    createdAt: parseDate(notificationResponse.created_at),
    updatedAt: parseDate(notificationResponse.updated_at),
    seenAt: parseNullableDate(notificationResponse.seen_at),
    interactedAt: parseNullableDate(notificationResponse.interacted_at),
  }
}

export const parseWebsiteOpportunityCreatedNotificationResponse = (
  notification: WebsiteOpportunityCreatedNotificationResponse
) => {
  return {
    opportunityId: notification.data.opportunity_id,
    mortgagor: {
      firstName: notification.data.mortgagor.first_name,
      lastName: notification.data.mortgagor.last_name,
      email: null,
    },
  }
}

export const parseMortgageFilesUploadedNotificationResponse = (
  notification: MortgageFilesUploadedNotificationResponse
) => {
  return {
    opportunityId: notification.data.opportunity_id,
    mortgagor: {
      firstName: notification.data.mortgagor.first_name,
      lastName: notification.data.mortgagor.last_name,
      email: notification.data.mortgagor.email,
      id: notification.data.mortgagor.id,
    },
    files: notification.data.files,
  }
}

export const parseOpportunityContributedNotificationResponse = (
  notification: OpportunityContributedNotificationResponse
) => {
  return {
    opportunityId: notification.data.opportunity_id,
    mortgagor: {
      firstName: notification.data.mortgagor.first_name,
      lastName: notification.data.mortgagor.last_name,
    },
    leadContributor: {
      firstName: notification.data.lead_contributor.first_name,
      lastName: notification.data.lead_contributor.last_name,
    },
  }
}

export const parseNotification = (notificationResponse: GetNotificationResponse): Notification | void => {
  if (isMortgageFilesUploadedNotificationResponse(notificationResponse)) {
    return {
      ...parseBaseNotification(notificationResponse),
      _type: 'Notification::MortgageFilesUploaded',
      data: parseMortgageFilesUploadedNotificationResponse(notificationResponse),
    }
  }

  if (isWebsiteOpportunityCreatedNotificationResponse(notificationResponse)) {
    return {
      ...parseBaseNotification(notificationResponse),
      _type: 'Notification::WebsiteOpportunityCreated',
      data: parseWebsiteOpportunityCreatedNotificationResponse(notificationResponse),
    }
  }

  if (isOpportunityContributedNotificationResponse(notificationResponse)) {
    return {
      ...parseBaseNotification(notificationResponse),
      _type: 'Notification::OpportunityContributed',
      data: parseOpportunityContributedNotificationResponse(notificationResponse),
    }
  }

  Sentry.withScope(scope => {
    scope.setExtra('notificationResponse', notificationResponse)
    Sentry.captureException(new Error(`Unknown notification type: ${notificationResponse.type}`))
  })
}
