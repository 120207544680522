import { downloadFile } from 'helpers/downloadFile'
import { ENDPOINTS } from 'helpers/endpoints'
import { useFetchApi } from 'hooks/api.hook'
import { useEffect } from 'react'
import { t } from 'utils/i18n'

export const useDepositCertificate = () => {
  const { fetchApi, data, loading } = useFetchApi<Blob>(ENDPOINTS.GENERATE_DEPOSIT_PDF)

  useEffect(() => {
    downloadFile(data, t('certificates.deposit_filename'))
  }, [data])

  return {
    fetchDepositCertificate: fetchApi,
    isDepositLoading: loading,
  }
}

export const useFeasabilityCertificate = () => {
  const { fetchApi, data, loading } = useFetchApi<Blob>(ENDPOINTS.GENERATE_FEASABILITY_PDF)

  useEffect(() => {
    downloadFile(data, t('certificates.feasability_filename'))
  }, [data])

  return {
    fetchFeasabilityCertificate: fetchApi,
    isFeasabilityLoading: loading,
  }
}

export const useSimulationCertificate = () => {
  const { fetchApi, data, loading } = useFetchApi<Blob>(ENDPOINTS.GENERATE_SIMULATION_PDF)

  useEffect(() => {
    downloadFile(data, t('certificates.simulation_filename'))
  }, [data])

  return {
    fetchSimulationCertificate: fetchApi,
    isSimulationLoading: loading,
  }
}

export const useConsultancyContract = () => {
  const { fetchApi, loading } = useFetchApi(ENDPOINTS.GENERATE_CONSULTANCY_CONTRACT)

  return {
    fetchConsultancyContract: fetchApi,
    isConsultancyContractLoading: loading,
  }
}
