import { MortgageFilesUploadedNotification } from 'components/notifications/mortgageFilesUploadedNotification'
import { WebsiteOpportunityCreatedNotification } from 'components/notifications/websiteOpportunityCreatedNotification'
import { formatTiming } from 'helpers/notification'
import React from 'react'
import {
  Notification as INotification,
  isMortgageFilesUploadedNotification,
  isOpportunityContributedNotification,
  isWebsiteOpportunityCreatedNotification,
} from 'types/notification'
import { OpportunityContributedNotification } from './opportunityContributedNotification'

type NotificationProps = {
  notification: INotification
}
export const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const interacted = notification.interactedAt !== null
  const timing = formatTiming(notification.createdAt)
  return (
    <>
      {isWebsiteOpportunityCreatedNotification(notification) && (
        <WebsiteOpportunityCreatedNotification
          key={notification.id}
          id={notification.id}
          opportunityId={notification.data.opportunityId}
          mortgagor={notification.data.mortgagor}
          timing={timing}
          interacted={interacted}
        />
      )}
      {isMortgageFilesUploadedNotification(notification) && (
        <MortgageFilesUploadedNotification
          key={notification.id}
          id={notification.id}
          opportunityId={notification.data.opportunityId}
          mortgagor={notification.data.mortgagor}
          files={notification.data.files}
          timing={timing}
          interacted={interacted}
        />
      )}
      {isOpportunityContributedNotification(notification) && (
        <OpportunityContributedNotification
          key={notification.id}
          id={notification.id}
          opportunityId={notification.data.opportunityId}
          mortgagor={notification.data.mortgagor}
          leadContributor={notification.data.leadContributor}
          timing={timing}
          interacted={interacted}
        />
      )}
    </>
  )
}
