import { XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { id } from 'helpers/ref'
import React, { forwardRef } from 'react'

interface ITextInputProps {
  className?: string
  id?: string
  name?: string
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  placeholder?: string
  disabled?: boolean
  value?: string
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  autoFocus?: boolean
  error?: boolean
  enableClear?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  ({ onChange, onBlur, onFocus, value, Icon, className, enableClear = true, ...props }, ref) => {
    const inputId = props.id || id()

    return (
      <div
        className={classNames(className, 'group', 'textInput', 'textInput--small', {
          'textInput--disabled': props.disabled,
        })}
      >
        {Icon && (
          <label htmlFor={inputId}>
            <Icon className="group-focus-within:text-blue-600" />
          </label>
        )}
        <input
          type="text"
          name={props.name}
          ref={ref}
          id={inputId}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={value}
          onChange={({ currentTarget: { value } }) => onChange(value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {enableClear && (
          <button type="button" onClick={() => onChange('')} className="group-focus-within:visible invisible">
            <XMarkIcon />
          </button>
        )}
      </div>
    )
  }
)
TextInput.displayName = 'TextInput'
