import styled from 'styled-components'

export const KanbanDisplayArea = styled.div.attrs({ className: 'flex grow h-full relative' })``

export const KanbanWrapper = styled.div.attrs({
  className: 'absolute inset-0 flex gap-x-4 w-full overflow-x-auto snap-x snap-mandatory',
})``

export const KanbanColumnHeader = styled.div.attrs({
  className: 'p-4',
})``

export const KanbanColumnBody = styled.div.attrs({
  className: 'max-w-full flex flex-col grow p-4 pb-4 space-y-4',
})``
