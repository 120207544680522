import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { I18NPath, t } from 'utils/i18n'

import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { Opportunity } from 'lib/oleenApi'
import { Badge } from 'ui/badges/badge'
import { useRepresentativeContext } from 'contexts/representative'
import classNames from 'classnames'

interface DocumentsCardDERRowProps {
  opportunity: Opportunity
}

const descriptionTexts = {
  success: 'opportunity.der_row.send_success',
  pending: 'opportunity.der_row.to_send',
  error: 'opportunity.der_row.send_error',
}
const derRequiredInfos = ['firstName', 'lastName', 'companyName', 'address', 'siret', 'oriasId', 'phone']
enum DER_STATE {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

export const DocumentsCardDERRow: React.FC<DocumentsCardDERRowProps> = ({ opportunity }) => {
  const { representative } = useRepresentativeContext()
  const derState = opportunity?.derSentAt
    ? DER_STATE.SUCCESS
    : representative?.isDerInfosPresent
      ? DER_STATE.PENDING
      : DER_STATE.ERROR
  const badgeText = derState === DER_STATE.SUCCESS ? t('opportunity.der_row.sent') : t('opportunity.der_row.not_sent')
  const missingInfos = derRequiredInfos.filter(info => !representative?.[info as keyof typeof representative])
  const badgeColor = {
    'badge--green': derState === DER_STATE.SUCCESS,
    'badge--gray': derState === DER_STATE.PENDING,
    'badge--orange': derState === DER_STATE.ERROR,
  }
  const descriptionTextColor = {
    'text-gray-500': derState === DER_STATE.SUCCESS || derState === DER_STATE.PENDING,
    'text-orange-600': derState === DER_STATE.ERROR,
  }

  return (
    <VerticalItem>
      <div className="flex justify-between items-center gap-6">
        <div className="flex flex-col items-start">
          <p className="text-sm font-bold antialiased leading-6 text-gray-800">{t('opportunity.der_row.title')}</p>
          <div className={classNames(descriptionTextColor, 'flex gap-1 items-start text-xs font-normal antialiased')}>
            {derState === DER_STATE.ERROR ? (
              <ExclamationTriangleIcon className="w-5 h-5 shrink-0" />
            ) : (
              <InformationCircleIcon className="w-5 h-5 shrink-0" />
            )}
            <div className="flex-col">
              {t(`${descriptionTexts[derState]}` as I18NPath)}
              <br />
              {derState === DER_STATE.ERROR && (
                <ul className="list-disc ml-5">
                  {missingInfos.map(info => (
                    <li key={info}>{t(`opportunity.der_row.missing_infos.${info}` as I18NPath)}</li>
                  ))}
                </ul>
              )}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-xs font-normal antialiased underline"
                href={t('opportunity.der_row.help.link')}
              >
                {t('opportunity.der_row.help.text')}
              </a>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <Badge className={classNames(badgeColor, 'badge--default text-xs')}>{badgeText}</Badge>
        </div>
      </div>
    </VerticalItem>
  )
}
