import styled from 'styled-components'

type Props = {
  width: number
}

export const ProgressBarFill = styled.div.attrs({
  className: `bg-blue-600 h-1.5 rounded-sm`,
})<Props>`
  width: ${(p: Props) => p.width}%;
`
