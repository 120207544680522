import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'

export enum API_VERB {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const apiRq = async <T, E>(
  path: string,
  verb: string,
  token: string,
  queryOrPayload?: unknown,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T & { error?: E }>> => {
  const api = process.env.API_BASE_URL

  const data = verb !== API_VERB.GET ? queryOrPayload : undefined
  const params = verb === API_VERB.GET ? queryOrPayload : undefined

  const response = await axios(api + path, {
    method: verb as Method,
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params,
    data,
  })

  return response
}
