import styled from 'styled-components'

interface IGridItemProps {
  colSpan?: string
}

interface IGrid {
  templateColumns: string
  gap: string
}
export const Grid = styled.div.attrs<IGrid>(({ gap, templateColumns }) => ({
  className: `grid ${templateColumns} ${gap}`,
}))<IGrid>``

export const GridItem = styled.div.attrs<IGridItemProps>(({ colSpan }) => ({
  className: `${colSpan || ''}`,
}))<IGridItemProps>``
