import { Buffer } from 'buffer'
import { StackedBarItem } from 'components/opportunities/atoms/tooltipSumCategory/tooltipSumCategory.types'
import { GenerateMandatePayload } from 'helpers/endpoints'
import { formatAmount, formatFullName, isAddress, isDefined, isNonEmptyString } from 'helpers/format'
import { CompetitorType } from 'types/competitor'
import { InterrogationStage, Opportunity, OPPORTUNITY_STATUS, Representative, SourceType } from 'lib/oleenApi'
import { t } from 'utils/i18n'

import { DropdownOption } from 'ui/dropdowns/dropdown'

export enum OPPORTUNITY_VIEW_MODE {
  LIST = 'list',
  KANBAN = 'kanban',
}

interface ValidationObject {
  isValid: boolean
  invalidFields: string[]
}

type ValidationFunc = (value: unknown) => boolean

export const OPPORTUNITY_FIELDS = {
  BOS_FILES_UPLOADED: 'bos_files_uploaded',
  BROKERAGE_FEES: 'brokerage_fees',
  SOURCE_TYPE: 'prescription_type',
  SOURCE_LEAD_CONTRIBUTOR: 'lead_contributor_id',
  SOURCE_AMOUNT: 'prescription_fees',
  STATUS: 'status',
  SUBTITLE: 'subtitle',
  BANK_APPOINTMENT_ON: 'bank_appointment_on',
  BANK_OFFER_RECEIVED_ON: 'bank_offer_received_on',
  BANK_OFFER_ACCEPTED_ON: 'bank_offer_accepted_on',
  BANK_CONTACT: 'bank_contact',
}

export enum OPPORTUNITY_SOURCE_TYPE_VALUES {
  NON_RENSEIGNE = 'none',
  PRESCRIPTEUR = 'profesional',
  PARRAINAGE = 'patronage',
  PRETTO = 'pretto',
  WEBSITE = 'website',
}

export enum DISABLED_SOURCE_TYPE_VALUES {
  PRETTO = 'pretto',
  WEBSITE = 'website',
}

export const getCompetitorTypeTranslated = (competitorType: CompetitorType) =>
  ({
    [CompetitorType.broker]: t('opportunity.opportunity_lost_reason_competitor_name_broker'),
    [CompetitorType.bank]: t('opportunity.opportunity_lost_reason_competitor_name_bank'),
  })[competitorType]

export const getInterrogationStageTranslated = (interrogationStage: InterrogationStage) =>
  ({
    [InterrogationStage.client_approved]: t('opportunity.interrogation.stage.client_approved'),
    [InterrogationStage.bank_approved]: t('opportunity.interrogation.stage.bank_approved'),
    [InterrogationStage.refused]: t('opportunity.interrogation.stage.refused'),
    [InterrogationStage.dropped]: t('opportunity.interrogation.stage.dropped'),
    [InterrogationStage.sent]: t('opportunity.interrogation.stage.sent'),
    [InterrogationStage.pending]: t('opportunity.interrogation.stage.pending'),
    [InterrogationStage.sending_in_progress]: t('opportunity.interrogation.stage.sending_in_progress'),
    [InterrogationStage.sending_error]: t('opportunity.interrogation.stage.sending_error'),
    [InterrogationStage.approval_denied]: t('opportunity.interrogation.stage.approval_denied'),
    [InterrogationStage.approval_pending]: t('opportunity.interrogation.stage.approval_pending'),
  })[interrogationStage]

const getSourceType = (value: OPPORTUNITY_SOURCE_TYPE_VALUES) =>
  ({
    [OPPORTUNITY_SOURCE_TYPE_VALUES.NON_RENSEIGNE]: SourceType.none,
    [OPPORTUNITY_SOURCE_TYPE_VALUES.PRESCRIPTEUR]: SourceType.profesional,
    [OPPORTUNITY_SOURCE_TYPE_VALUES.PARRAINAGE]: SourceType.patronage,
    [OPPORTUNITY_SOURCE_TYPE_VALUES.PRETTO]: SourceType.pretto,
    [OPPORTUNITY_SOURCE_TYPE_VALUES.WEBSITE]: SourceType.website,
  })[value]

const isSourceDisabled = (value: string) => {
  const disabledSourceTypes: string[] = Object.values(DISABLED_SOURCE_TYPE_VALUES)
  return disabledSourceTypes.includes(value)
}

export const BOS_STATUSES_START_INDEX = 6

const BOS_ALERT_OPPORTUNITY_STATUS = {
  ENVOYE_BANQUE: 'sent_to_bank',
  ACCORD_BANQUE: 'got_bank_agreement',
  ACCORD_CLIENT: 'got_customer_agreement',
  PLANNED_BANK_MEETING: 'bank_meeting_scheduled',
  EDITED_OFFER: 'offer_edited',
  SIGNED_OFFER: 'offer_signed',
  FONDS_DEBLOQUES: 'funds_released',
} as const

type BOS_ALERT_OPPORTUNITY_STATUS_VALUES =
  (typeof BOS_ALERT_OPPORTUNITY_STATUS)[keyof typeof BOS_ALERT_OPPORTUNITY_STATUS]

export const isBosAlertStatus = (status: OPPORTUNITY_STATUS): boolean =>
  Object.values(BOS_ALERT_OPPORTUNITY_STATUS).includes(status as BOS_ALERT_OPPORTUNITY_STATUS_VALUES)

export const OPPORTUNITY_SOURCE_OPTIONS: DropdownOption[] = Object.values(OPPORTUNITY_SOURCE_TYPE_VALUES).map(
  value => ({
    type: 'text',
    label: t(`opportunity.source_type.${getSourceType(value)}`),
    value,
    isDisabled: isSourceDisabled(value),
  })
)

export const validateMandatePayload = (
  opportunity: Opportunity,
  representative: Representative | null
): ValidationObject => {
  const { hasCoMortgagor } = opportunity
  const asValidationSchema = <T extends Partial<Record<keyof Opportunity, ValidationFunc>>>(schema: T) => schema
  const mandateValidationSchema = asValidationSchema({
    brokerageFees: Number.isFinite,
    mortgagorAddress: isDefined,
    mortgagorFirstName: isNonEmptyString,
    mortgagorLastName: isNonEmptyString,
    mortgagorEmail: isNonEmptyString,
    projectKind: isNonEmptyString,
    propertyKind: isNonEmptyString,
    goodUsage: isNonEmptyString,
    oriasId: isNonEmptyString,
    goodAddress: isAddress,
    coMortgagorFirstName: value => !hasCoMortgagor || isNonEmptyString(value),
    coMortgagorLastName: value => !hasCoMortgagor || isNonEmptyString(value),
    coMortgagorAddress: value => !hasCoMortgagor || isDefined(value),
    coMortgagorEmail: value => !hasCoMortgagor || isDefined(value),
  })

  return Object.entries(mandateValidationSchema).reduce<ValidationObject>(
    (validationObj, [fieldName, validateField]) => {
      const { invalidFields } = validationObj
      let isFieldValid = validateField(opportunity[fieldName as keyof Opportunity])

      switch (fieldName) {
        case 'oriasId':
          isFieldValid = !!representative?.oriasId
          break
        default:
      }

      if (!isFieldValid) {
        return {
          isValid: false,
          invalidFields: [
            ...invalidFields,
            t(`opportunity.mandate_modal.missingData.${fieldName as keyof typeof mandateValidationSchema}`),
          ],
        }
      }

      return validationObj
    },
    { isValid: true, invalidFields: [] }
  )
}

export const buildSendMandatePayload = (comment?: string): GenerateMandatePayload => ({
  comment: Buffer.from(comment || '', 'utf-8').toString('base64'),
})

export const getOpportunityStatusBadgeColor = (status: OPPORTUNITY_STATUS): string => {
  switch (status) {
    case OPPORTUNITY_STATUS.A_JOINDRE:
      return 'badge--orange'
    case OPPORTUNITY_STATUS.RELANCE_DOC:
      return 'badge--yellow'
    case OPPORTUNITY_STATUS.EN_MONTAGE:
      return 'badge--blue'
    case OPPORTUNITY_STATUS.ENVOYE_BANQUE:
      return 'badge--pink'
    case OPPORTUNITY_STATUS.ACCORD_BANQUE:
      return 'badge--purple'
    case OPPORTUNITY_STATUS.ACCORD_CLIENT:
      return 'badge--purple'
    case OPPORTUNITY_STATUS.PLANNED_BANK_MEETING:
      return 'badge--indigo'
    case OPPORTUNITY_STATUS.EDITED_OFFER:
      return 'badge--teal'
    case OPPORTUNITY_STATUS.SIGNED_OFFER:
      return 'badge--teal'
    case OPPORTUNITY_STATUS.FONDS_DEBLOQUES:
      return 'badge--green'
    case OPPORTUNITY_STATUS.PERDU:
      return 'badge--gray'
    default:
      return 'badge--gray'
  }
}

export const getOpportunityTitle = (opportunity: Opportunity): string => {
  return formatFullName(opportunity.mortgagorFirstName ?? '', opportunity.mortgagorLastName ?? '')
}

export const mapOpportunitiesOnStatus = (opportunities: Opportunity[]): Record<string, Opportunity[]> => {
  const statusMap = Object.values(OPPORTUNITY_STATUS).reduce<Record<string, Opportunity[]>>((map, status) => {
    map[status] = []
    return map
  }, {})

  opportunities.forEach(opportunity => {
    const { status } = opportunity
    statusMap[status] = [...statusMap[status], opportunity]
  })

  return statusMap
}

export const sumBankAndBrokerageFees = (opportunities: Opportunity[]): string =>
  formatAmount(
    opportunities.reduce(
      (sum, { brokerageFees, bankCommission }) => sum + (bankCommission || 0) + (brokerageFees || 0),
      0
    )
  )

export const sumBankCommission = (opportunities: Opportunity[]): number =>
  opportunities.reduce((sum, { bankCommission }) => sum + (bankCommission || 0), 0)

export const sumBrokerageFees = (opportunities: Opportunity[]): number =>
  opportunities.reduce((sum, { brokerageFees }) => sum + (brokerageFees || 0), 0)

export const determineStackedBarItemPercentageShare = (arr: StackedBarItem[], stackedBarItemFeeAmount: number) => {
  const totalFeeAmount = arr.reduce((sum, { amount }) => sum + (amount || 0), 0)
  return (stackedBarItemFeeAmount / totalFeeAmount) * 100
}
