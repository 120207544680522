type BaseNotification = {
  id: string
  createdAt: Date
  updatedAt: Date
  seenAt: Date | null
  interactedAt: Date | null
}

export type MortgageFilesUploadedNotification = BaseNotification & {
  _type: 'Notification::MortgageFilesUploaded'
  data: {
    files: string[]
    opportunityId: string
    mortgagor: {
      firstName: string | null
      lastName: string | null
      email: string | null
      id: string | null
    }
  }
}

export const isMortgageFilesUploadedNotification = (
  notification: Notification
): notification is MortgageFilesUploadedNotification => notification._type === 'Notification::MortgageFilesUploaded'

export type WebsiteOpportunityCreatedNotification = BaseNotification & {
  _type: 'Notification::WebsiteOpportunityCreated'
  data: {
    opportunityId: string
    mortgagor: {
      firstName: string | null
      lastName: string | null
      email: string | null
    }
  }
}

export const isWebsiteOpportunityCreatedNotification = (
  notification: Notification
): notification is WebsiteOpportunityCreatedNotification =>
  notification._type === 'Notification::WebsiteOpportunityCreated'

export type OpportunityContributedNotification = BaseNotification & {
  _type: 'Notification::OpportunityContributed'
  data: {
    opportunityId: string
    leadContributor: {
      firstName: string | null
      lastName: string | null
    }
    mortgagor: {
      firstName: string | null
      lastName: string | null
    }
  }
}

export const isOpportunityContributedNotification = (
  notification: Notification
): notification is OpportunityContributedNotification => notification._type === 'Notification::OpportunityContributed'

export type Notification =
  | MortgageFilesUploadedNotification
  | WebsiteOpportunityCreatedNotification
  | OpportunityContributedNotification

export interface Notifications {
  notifications: Notification[]
  total: number
  page: number
  perPage: number
  nextPage: number | null
  prevPage: number | null
}
