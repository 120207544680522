import React from 'react'

import { IconButton as StyledIconButton } from './button.styled'

interface IIconButtonProps {
  variant: string
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  onClick?: () => void
  title?: string
}

export const IconButton: React.FC<IIconButtonProps> = ({ variant, Icon, ...props }) => (
  <StyledIconButton variant={variant} {...props}>
    <Icon className="h-5 w-5" />
  </StyledIconButton>
)
