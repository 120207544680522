import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

interface KanbanColumnProps {
  isStriped?: boolean
}

export const KanbanColumn: React.FC<PropsWithChildren<KanbanColumnProps>> = ({ isStriped, children }) => {
  return <div className={classNames('column', { 'column--stripped': isStriped })}>{children}</div>
}
