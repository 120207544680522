import React from 'react'

import { AccordionItem as StyledAccordionItem } from 'ui/legacy/atoms/accordionItem/accordionItem.styled'

interface IAccordionItemProps {
  children: React.ReactNode
  isOpen: boolean
}

export const AccordionItem: React.FC<IAccordionItemProps> = ({ isOpen, children }) => (
  <StyledAccordionItem isOpen={isOpen}>{children}</StyledAccordionItem>
)
